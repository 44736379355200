<nz-spin [nzSpinning]="loading">
    <div *ngIf="clientNotificationConfig" nz-col nzSpan="24">
        <ng-container *ngIf="client">
            <p *ngIf="client.clientEmail"><strong>Klients (To): </strong>{{ client.clientEmail }}</p>

            <p *ngIf="!client.clientEmail"><strong>Klients (To):</strong></p>
            <p *ngIf="!client.clientEmail">Klientam nav norādīta e-pasta adrese!</p>
        </ng-container>

        <ng-container *ngIf="contact">
            <p><strong>Kontakts (To):</strong></p>
            <label *ngIf="contact.contactEmail" nz-checkbox [(ngModel)]="clientNotificationConfig.sendToContact">{{ contact.contactEmail }}</label>
            <p *ngIf="!contact.contactEmail">Kontaktam nav norādīta e-pasta adrese!</p>
        </ng-container>

        <ng-container *ngIf="client && client.webUsers && client.webUsers.length > 0">
            <p class="mt-2"><strong>Portāla lietotāji (Bcc)</strong></p>
            <div nz-row>
                <div nz-col nzSpan="12" *ngFor="let webUser of client.webUsers">
                    <label nz-checkbox [(ngModel)]="webUser.selected">{{ webUser.login }}</label>
                </div>
            </div>
        </ng-container>
        
        <p class="mt-2"><strong>Papildus teksts</strong></p>
        <textarea rows="8" nz-input placeholder="Papildus teksts e-pastā" [(ngModel)]="clientNotificationConfig.additionalMessage"></textarea>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" [disabled]="loading" (click)="cancel()">Atcelt</button>
        <button nz-button nzType="primary" [disabled]="loading || (client && !client.clientEmail) || (!client && contact && !contact.contactEmail)" (click)="sendNotification()">Nosūtīt</button>
    </div>
</nz-spin>
