import { Component } from '@angular/core';
import { UsersService } from '@lui/core/services';
import { User } from '@lui/shared/models';
import { EmployeeDocumentNotification } from '@lui/shared/models/employee-document-notification-options.model';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'lui-document-notification-to-employee-modal',
    standalone: false,
    templateUrl: './document-notification-to-employee-modal.component.html',
    styleUrl: './document-notification-to-employee-modal.component.less',
})
export class DocumentNotificationToEmployeeModalComponent {
    loading: boolean = false;
    users: User[];

    employeeDocumentNotificationOptions: EmployeeDocumentNotification;

    showUserRequiredError: boolean = false;

    constructor(private modal: NzModalRef, private usersService: UsersService) {
        this.employeeDocumentNotificationOptions = {
            receiverUserId: null,
            additionalMessage: null,
            documentId: null,
        };
    }

    userToString(user: User): string {
        return this.usersService.toString(user);
    }

    submitAndReturn(): void {
        if (!this.employeeDocumentNotificationOptions.receiverUserId) {
            this.showUserRequiredError = true;
            return;
        }

        this.showUserRequiredError = false;
        this.modal.close(this.employeeDocumentNotificationOptions);
    }

    cancel(): void {
        this.modal.destroy();
    }
}
