<nz-spin [nzSpinning]="loading" *ngIf="objectForm">
    <form nz-form [formGroup]="objectForm" class="login-form" nzLayout="vertical">
        <div nz-row nzGutter="8">
            <div nz-col [nzXXl]="12" [nzXl]="12" [nzLg]="12">
                <nz-form-item>
                    <nz-form-label nzRequired nzFor="documentTypeId">Dokumenta veids</nz-form-label>
                    <nz-form-control luiValidationError="documentTypeId">
                        <nz-select nzShowSearch nzPlaceHolder="Dokumenta veids" formControlName="documentTypeId">
                            <nz-option
                                *ngFor="let documentType of documentTypes$ | async"
                                [nzLabel]="documentType.name"
                                [nzValue]="documentType.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired nzFor="number">Dokumenta numurs</nz-form-label>
                    <nz-form-control luiValidationError="number">
                        <input type="text" nz-input formControlName="number" id="number" />
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzFor="date">Datums</nz-form-label>
                    <nz-form-control luiValidationError="date">
                        <nz-date-picker
                            class="w-100"
                            id="date"
                            formControlName="date"
                            [nzFormat]="'dd.MM.yyyy'"
                            [nzPlaceHolder]="'Datums'"
                        ></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzFor="validUntil">Derīgs līdz</nz-form-label>
                    <nz-form-control luiValidationError="validUntil">
                        <nz-date-picker
                            class="w-100"
                            id="validUntil"
                            formControlName="validUntil"
                            [nzFormat]="'dd.MM.yyyy'"
                            [nzPlaceHolder]="'Derīgs līdz'"
                        ></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col [nzXXl]="12" [nzXl]="24" [nzLg]="12">
                <nz-form-item>
                    <nz-form-label nzFor="notes">Piezīmes</nz-form-label>
                    <nz-form-control luiValidationError="notes">
                        <textarea rows="8" nz-input class="w-100" id="notes" formControlName="notes" placeholder="Piezīmes"></textarea>
                    </nz-form-control>
                </nz-form-item>
                <div nz-row class="align-items-center justify-content-center">
                    <nz-form-item *ngIf="canEditFields">
                        <nz-form-label nzFor="isGenerated" [nzNoColon]="true"></nz-form-label>
                        <nz-form-control>
                            <label nz-checkbox id="isGenerated" formControlName="isGenerated">Ģenerēts</label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="canEditFields">
                        <nz-form-label nzFor="isPrintableInContract" [nzNoColon]="true"></nz-form-label>
                        <nz-form-control>
                            <label nz-checkbox id="isPrintableInContract" formControlName="isPrintableInContract">Iekļaut līgumā</label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="canEditFields">
                        <nz-form-label nzFor="isPublishableInPortal" [nzNoColon]="true"></nz-form-label>
                        <nz-form-control>
                            <label nz-checkbox id="isPublishableInPortal" formControlName="isPublishableInPortal">Publicēt portalā</label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="canEditFields">
                        <nz-form-label nzFor="isPublishableInProfile" [nzNoColon]="true"></nz-form-label>
                        <nz-form-control>
                            <label nz-checkbox id="isPublishableInProfile" formControlName="isPublishableInProfile">Rādīt izpildītāja profilā</label>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item *ngIf="canEditFields">
                        <nz-form-label nzFor="isSignable" [nzNoColon]="true"></nz-form-label>
                        <nz-form-control>
                            <label nz-checkbox id="isSignable" formControlName="isSignable">Ir parakstāms</label>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>

        <ng-container *ngIf="canEditFields && isEditMode">
            <button nz-button class="btn-warning mb-3" (click)="sendNotificationToEmployee()">Nosūtīt atgādinājumu darbiniekam</button>
            <br />
            <span *ngIf="originalObject.lastEmployeeNotificationTimestamp"
                >Pēdējais paziņojums darbiniekam nosūtīts: {{ originalObject.lastEmployeeNotificationTimestamp | date : dateTimeFormat }}</span
            >
        </ng-container>

        <nz-collapse [nzBordered]="false" *ngIf="isEditMode">
            <nz-collapse-panel [nzHeader]="'Dokumenta faili'" [nzActive]="true">
                <nz-upload
                    [nzAccept]="
                        'image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain'
                    "
                    class="mb-3"
                    [nzSize]="5000"
                    [nzBeforeUpload]="beforeDocumentFileUpload"
                    [nzShowUploadList]="false"
                    [nzCustomRequest]="customUploadRequest"
                >
                    <button nz-button><i nz-icon nzType="upload"></i>Pievienot</button>
                </nz-upload>
                <lui-document-files-table
                    [document]="originalObject"
                    [documentIsSignable]="documentIsSignable"
                    [canEditFields]="canEditFields"
                    [isEditMode]="isEditMode"
                ></lui-document-files-table>
            </nz-collapse-panel>
        </nz-collapse>
        <div *nzModalFooter>
            <ng-container *ngIf="canEditFields">
                <button nz-button nzType="default" [disabled]="loading" (click)="cancel()">Atcelt</button>
                <button nz-button nzType="primary" [disabled]="loading" (click)="validateAndSave()">Saglabāt</button>
            </ng-container>
        </div>
    </form>
</nz-spin>
