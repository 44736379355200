/**
 * @module ol/control
 */
export { default as Attribution } from './control/Attribution.js';
export { default as Control } from './control/Control.js';
export { default as FullScreen } from './control/FullScreen.js';
export { default as MousePosition } from './control/MousePosition.js';
export { default as OverviewMap } from './control/OverviewMap.js';
export { default as Rotate } from './control/Rotate.js';
export { default as ScaleLine } from './control/ScaleLine.js';
export { default as Zoom } from './control/Zoom.js';
export { default as ZoomSlider } from './control/ZoomSlider.js';
export { default as ZoomToExtent } from './control/ZoomToExtent.js';
export { defaults } from './control/defaults.js';