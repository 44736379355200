import { Component, OnInit, Type } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { ClassifierService, ContactsService } from '@lui/core/services';
import { Contact } from '@lui/shared/models/contact.model';
import { Classifier, ClassifierType, PersonTypes } from '@lui/shared/models';
import { UpsertAddressComponent } from '../upsert-address/upsert-address.component';
import { BaseUpsertComponent } from '@lui/core/components/base-upsert-component';
import { Observable } from 'rxjs/internal/Observable';
import { atLeastOne } from '@lui/core/validators/at-least-one.validator';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'lui-upsert-contact',
    templateUrl: 'upsert-contact.component.html',
    standalone: false
})
export class UpsertContactComponent extends BaseUpsertComponent<Contact, ContactsService> implements OnInit {
    personTypes$: Observable<Classifier[]>;

    upsertAddressComponent: Type<UpsertAddressComponent> = UpsertAddressComponent;

    isSubmitPerformed: boolean = false;

    personTypes: typeof PersonTypes = PersonTypes;

    constructor(
        private modal: NzModalRef,
        private classifierService: ClassifierService,
        private contactsService: ContactsService,
        protected messageService: NzMessageService,
        private fb: UntypedFormBuilder,
    ) {
        super(contactsService, messageService);

        // set modal texts
        this.saveSuccessText = 'Kontakts saglabāts!';
        this.createObjectSaveErrorText = 'Kļūda pievienojot kontaktu';
        this.updateObjectSaveErrorText = 'Kļūda labojot kontaktu';
    }

    async ngOnInit(): Promise<void> {
        this.personTypes$ = this.classifierService.getClassifiers(ClassifierType.PersonTypes);
    }

    createForm(): UntypedFormGroup {
        return this.fb.group({
            name: [null, [Validators.required, Validators.maxLength(32)]],
            surname: [null, [Validators.maxLength(128)]],
            phone: [null, [Validators.maxLength(32)]],
            contactEmail: [null, [Validators.maxLength(128), Validators.email]],
            notes: [null, [Validators.maxLength(2000)]],
            personTypeId: [this.personTypes.Physical, [Validators.required]],
        });
    }

    createAndFillForm(): UntypedFormGroup {
        return this.fb.group({
            name: [this.originalObject.name, [Validators.required, Validators.maxLength(32)]],
            surname: [this.originalObject.surname, [Validators.maxLength(128)]],
            phone: [this.originalObject.phone, [Validators.maxLength(32)]],
            contactEmail: [this.originalObject.contactEmail, [Validators.maxLength(128)]],
            notes: [this.originalObject.notes, [Validators.maxLength(2000)]],
            personTypeId: [this.originalObject.personTypeId, [Validators.required]],
        });
    }

    async setEditMode(contact: Contact): Promise<void> {
        super.setEditMode(contact);
    }

    afterInit(): void {
        this.objectForm.validator = atLeastOne('phone', 'contactEmail');
    }

    async save(): Promise<void> {
        const contact = <Contact>this.objectForm.getRawValue();

        if (this.isEditMode) {
            await this.contactsService.update(this.objectId, Object.assign(this.originalObject, contact));
            this.modal.close(true);
            this.loading = false;
            return;
        }
        await this.contactsService.create(contact);
    }

    async validateAndSave(): Promise<void> {
        this.isSubmitPerformed = true;
        super.validateAndSave();
    }

    async submitAndReturn(val: boolean | Contact): Promise<void> {
        if (typeof val === 'boolean') {
            this.modal.close(val);
            return;
        }

        if (this.isEditMode) {
            // update object if is edit mode, because submitAndReturn is used in form controls, where user can add new value or edit existing one.
            await this.contactsService.update(this.objectId, Object.assign(this.originalObject, val));
        }

        this.modal.close(val);
    }

    cancel(): void {
        this.modal.destroy();
    }
}
