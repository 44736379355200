import { BaseModel } from './base-model.model';

export interface Classifier extends BaseModel {
    parentId: number;
    name: string;
    description: number;
    originalLuidaisId: number;
    tildeId: number;
    isActive: boolean;
    selected: boolean;
}

export enum ClassifierType {
    Groups = 1,
    GroupPermissions = 2,
    Permissions = 3,
    Sexes = 4,
    PersonTypes = 5,
    Vat = 6,
    InvoiceShowAccount = 7,
    ClientTypes = 8,
    PrintoutTypes = 9,
    EstimateStatusTypes = 10,
    AdvancePaymentTypes = 11,
    OwnershipTypes = 12,
    PaymentType = 13,
    PaymentStatus = 14,
    SContractType = 15,
}

export enum Permission {
    VIEW = 3001,
    NEW  = 3002,
    EDIT = 3003
}

// ids here are originalLuidaisId, because for now, originalLuidaisId is used as classifiers id!
export enum PersonTypes {
    Physical = 1,
    Legal = 2,
    Goverment = 3,
    Municipality = 4,
    Other = 5
}

export enum ClientTypes {
    VIP = 1,
    CreditorRegular = 2,
    Regular = 3,
    CreditorGeneral = 4
}

export enum PrintoutType {
    Estimate = 9001,
    Agreement = 9002
}

export enum PaymentStatus {
    Initial = 14001,
    Settled = 14002,
    Failed = 14003
}
