import { Component, Input } from '@angular/core';
import { File } from '@lui/shared/models/file.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ESignFlowSelectComponent } from './e-sign-flow-select/e-sign-flow-select.component';

@Component({
    selector: 'lui-e-sign',
    templateUrl: './e-sign.component.html',
    styleUrl: './e-sign.component.less',
    standalone: false
})
export class ESignComponent {
    @Input() disabled: boolean;
    @Input() file: File;
    @Input() signableFileType: string;

    constructor(private modalService: NzModalService) {}

    initializeESigning(): void {
        const modal = this.modalService.create({
            nzTitle: 'Autentifikācijas veida izvēle',
            nzContent: ESignFlowSelectComponent,
            nzWidth: 'fit-content',
            nzFooter: null,
        });

        modal.afterOpen.subscribe(() => {
            modal.getContentComponent().fileId = this.file.id;
            modal.getContentComponent().signableFileType = this.signableFileType;
        });
    }
}
