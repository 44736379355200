import { Component } from '@angular/core';
import { AddressPoint } from '@lui/shared/models/address-point.model';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'lui-address-select-modal',
    standalone: false,
    templateUrl: './address-select-modal.component.html',
    styleUrl: './address-select-modal.component.less',
})
export class AddressSelectModalComponent {
    addressPoints: AddressPoint[];
    filteredAddresses: AddressPoint[];

    searchText: string;

    constructor(private modal: NzModalRef) {}

    searchAddress(): void {
        this.filteredAddresses = this.addressPoints.filter((c: AddressPoint): boolean =>
            c.fullAddress.toLowerCase().includes(this.searchText.toLowerCase()),
        );
    }

    cancel(): void {
      this.modal.close();
    }

    selectAddress(address: AddressPoint): void {
        this.modal.close(address);
    }
}
