export enum EntityType {
    Client = 'client',
    Contact = 'contact',
    Company = 'company',
    OrderType = 'orderType',
    WorkType = 'workType',
    Department = 'department',
    PayoutType = 'payoutType',
    Document = 'document',
    Performer = 'performer'
}
