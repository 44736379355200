import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '@lui/shared/models/user.model';
import { BaseServiceWithFilter } from './base.service';
import { UsersDepartment } from '@lui/shared/models/users-department.model';
import { lastValueFrom, Observable } from 'rxjs';
import { UserFilter } from '@lui/shared/models/user-filter.model';
import { CommandResult } from '@lui/shared/models';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseServiceWithFilter<User, UserFilter> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, '/api/v1/users/');
    }

    delete(id: number): Promise<void> {
        throw new Error('Not allowed');
    }

    getPermissions(userId: number): Promise<UsersDepartment[]> {
        return this.httpClient.get<UsersDepartment[]>(`${this.baseApiUrl}${userId}/permissions`).toPromise();
    }

    setPermission(userId: number, departmentId: number, permissionId: number): Promise<number> {
        return this.httpClient.post<number>(`${this.baseApiUrl}${userId}/departments/${departmentId}/permissions/${permissionId}`, null).toPromise();
    }

    removePermission(userId: number, departmentId: number, permissionId: number): Promise<void> {
        return this.httpClient.delete<void>(`${this.baseApiUrl}${userId}/departments/${departmentId}/permissions/${permissionId}`).toPromise();
    }

    getUsersForRestriction(): Observable<User[]> {
        return this.httpClient.get<User[]>(`${this.baseApiUrl}for-restriction`);
    }

    isPersonCodeUnique(code: string, id: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseApiUrl}${code}/unique/${id}`);
    }

    isUsernameUnique(username: string, id: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.baseApiUrl}${username}/username-unique/${id}`);
    }

    getUsersForDocumentNotification(documentId: number): Promise<CommandResult<User[]>> {
        return lastValueFrom(this.httpClient.get<CommandResult<User[]>>(`${this.baseApiUrl}for-document-notification/${documentId}`));
    }

    async logoutUserById(userId: number): Promise<CommandResult<void>> {
        return lastValueFrom(this.httpClient.post<CommandResult<void>>(`${this.baseApiUrl}${userId}/logout`, null));
    }

    toString(object: User): string {
        return `${object.name || ''} ${object.surname || ''} ${object.userEmail ? ['(', object.userEmail, ')'].join('') : ''}`;
    }
}
