<nz-spin [nzSpinning]="loading" *ngIf="initialized">
    <div>
        <form nz-form [nzLayout]="'vertical'">
            <nz-form-item>
                <nz-form-control>
                    <nz-form-item>
                        <nz-form-label nzRequired>Lietotājs, kura dati parādīsies izdrukā</nz-form-label>
                        <nz-select nzPlaceHolder="Lietotājs" nzShowSearch [(ngModel)]="printoutDialogOptions.userId" [ngModelOptions]="{ standalone: true }">
                            <nz-option
                                *ngFor="let user of users$ | async"
                                [nzLabel]="userToString(user)"
                                [nzValue]="user.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-item>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control>
                    <nz-form-item>
                        <nz-form-label nzRequired>Izdrukas veidne</nz-form-label>
                        <nz-select
                            nzPlaceHolder="Izdrukas veidne"
                            [(ngModel)]="printoutDialogOptions.printoutTemplateId"
                            [ngModelOptions]="{ standalone: true }"
                        >
                            <nz-option *ngFor="let t of printoutTemplates" [nzLabel]="t.name" [nzValue]="t.id"></nz-option>
                        </nz-select>
                    </nz-form-item>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="cancel()">Atcelt</button>
        <button nz-button nzType="primary" (click)="submitAndReturn()">Drukāt</button>
    </div>
</nz-spin>
