import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { DocumentType } from '@lui/shared/models/document-type.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DocumentTypesService extends BaseService<DocumentType> {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'api/v1/document-types/');
    }

    getDocumentTypes(): Observable<DocumentType[]> {
      return this.httpClient.get<DocumentType[]>(`${this.baseApiUrl}`);
    }
}
