<nz-form-item *ngIf="!limitedSizes" [formGroup]="formGroup">
    <nz-form-label nzRequired nzFor="departmentId">Struktūrvienība</nz-form-label>
    <nz-form-control luiValidationError="departmentId">
        <nz-select nzShowSearch nzPlaceHolder="Struktūrvienība" (ngModelChange)="departmentChanged($event)" formControlName="departmentId">
            <nz-option-group *ngFor="let group of departmentGroups" [nzLabel]="group.groupName">
                <nz-option *ngFor="let d of group.departments" [nzLabel]="d.name" [nzValue]="d.id"></nz-option>
            </nz-option-group>
        </nz-select>
    </nz-form-control>
</nz-form-item>

<nz-form-item *ngIf="limitedSizes" [formGroup]="formGroup">
    <nz-form-label nzRequired [nzSm]="8" [nzXs]="24" nzFor="departmentId">Struktūrvienība</nz-form-label>
    <nz-form-control [nzSm]="16" [nzXs]="24" luiValidationError="departmentId">
        <nz-select nzShowSearch nzPlaceHolder="Struktūrvienība" (ngModelChange)="departmentChanged($event)" formControlName="departmentId">
            <nz-option-group *ngFor="let group of departmentGroups" [nzLabel]="group.groupName">
                <nz-option *ngFor="let d of group.departments" [nzLabel]="d.name" [nzValue]="d.id"></nz-option>
            </nz-option-group>
        </nz-select>
    </nz-form-control>
</nz-form-item>
