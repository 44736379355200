import { Component, OnInit, Input, Type, Output, EventEmitter, ViewChild } from '@angular/core';
import { BaseModel, EntityType } from '@lui/shared/models';
import { BaseUpsertComponent } from '@lui/core/components/base-upsert-component';
import { BaseService } from '@lui/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ServiceFactory } from '@lui/core/services/service.factory';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
    selector: 'lui-search-field',
    templateUrl: './search-field.component.html',
    styleUrls: ['search-field.component.less'],
    standalone: false,
})
export class SearchFieldComponent implements OnInit {
    @ViewChild('selectObject') selectObject: NzSelectComponent;
    @ViewChild('selectId') selectId: NzSelectComponent;

    @Input()
    fieldId: string;

    @Input()
    fieldLabel: string;

    @Input()
    fieldPlaceholder: string = 'Meklēt';

    @Input()
    idMode: boolean = false;

    @Input()
    modelType: EntityType;

    _object: BaseModel;
    @Input()
    set object(val: BaseModel) {
        this._object = val;
        if (!this.service?.toString) {
            return;
        }

        this.listOfOption = [];
        this.listOfOption.push({
            value: val,
            text: this.service.toString(val),
        });
    }
    get object(): BaseModel {
        return this._object;
    }

    @Input()
    id: number;

    @Output()
    idChange: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    objectChange: EventEmitter<BaseModel> = new EventEmitter<BaseModel>();

    @Input()
    upsertComponent: Type<BaseUpsertComponent<BaseModel, BaseService<BaseModel>>>;

    formChanged: boolean = false;

    listOfOption: Array<{ value: BaseModel; text: string }> = [];
    searchSubject: Subject<string> = new Subject<string>();

    nzFilterOption = () => true;
    service: BaseService<BaseModel>;
    isSearchPerformed: boolean;

    constructor(private modalService: NzModalService, private serviceFactory: ServiceFactory) {
        this.searchSubject.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (searchText: string): Promise<void> => {
            const foundOptions = (await this.service.search(searchText).toPromise()).data;
            this.listOfOption = [];
            foundOptions.forEach((d) => {
                this.listOfOption.push({
                    value: d,
                    text: this.service.toString(d),
                });
            });
            this.isSearchPerformed = true;

            // set input to be in focus again, because afther isSearchPeformed is set to true,
            // suffixActions takes over the focus, so it has to be set back for uninterupted search text inputting
            setTimeout(() => {
                requestAnimationFrame(() => {
                    this.idMode ? this.selectId.focus() : this.selectObject.focus();
                });
            });
        });
    }

    ngOnInit(): void {
        this.service = this.serviceFactory.getService(this.modelType);
    }

    async search(text: string): Promise<void> {
        if (text.length < 3) {
            return;
        }

        this.searchSubject.next(text);
    }

    objectSelectionChanged(newObject: BaseModel): void {
        this.objectChange.emit(newObject);
    }

    idSelectionChanged(newId: number): void {
        this.idChange.emit(newId);
    }

    async addObject(): Promise<void> {
        const modal = this.modalService.create({
            nzTitle: 'Pievienošana',
            nzContent: this.upsertComponent,
        });

        modal.afterOpen.subscribe(() => {
            const component = modal.getContentComponent();
            component.returnObject = true;
            component.initForm();
            component.afterInit();
            component.objectForm.markAsDirtyAndUpdateAll();
        });

        modal.afterClose.subscribe(async (obj: BaseModel) => {
            if (obj) {
                this.object = obj;
                this.objectChange.emit(this.object);
                this.formChanged = true;
                this.listOfOption.push({
                    value: this.object,
                    text: this.service.toString(this.object),
                });
            }
        });
    }

    async editObject(): Promise<void> {
        const modal = this.modalService.create({
            nzTitle: 'Labošana',
            nzContent: this.upsertComponent,
        });

        const fullObjectData = this.object.id ? (await this.service.getSingle(this.object.id)).data : this.object;

        modal.afterOpen.subscribe(() => {
            const component = modal.getContentComponent();
            component.setEditMode(fullObjectData);
            component.returnObject = true;
            component.objectForm.markAsDirtyAndUpdateAll();
        });

        modal.afterClose.subscribe(async (obj: BaseModel) => {
            if (obj) {
                this.object = obj;
                this.objectChange.emit(this.object);
            }
        });
    }

    clearSelection(): void {
        this.objectSelectionChanged(null);
    }
}
