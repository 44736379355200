<nz-spin [nzSpinning]="loading">
    <div>
        <form nz-form [nzLayout]="'vertical'">
            <nz-form-item>
                <nz-form-control>
                    <nz-form-item>
                        <nz-form-label nzRequired>Lietotājs</nz-form-label>
                        <nz-select
                            nzShowSearch
                            nzPlaceHolder="Lietotājs"
                            [(ngModel)]="employeeDocumentNotificationOptions.receiverUserId"
                            [ngModelOptions]="{ standalone: true }"
                        >
                            <nz-option *ngFor="let user of users" [nzLabel]="userToString(user)" [nzValue]="user.id"></nz-option>
                        </nz-select>
                    </nz-form-item>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label>Papildus info (redzama e-pasta tekstā)</nz-form-label>
                <nz-form-control>
                    <textarea
                        name="additionalMessage"
                        rows="8"
                        maxlength="512"
                        nz-input
                        placeholder="Papildus info"
                        [(ngModel)]="employeeDocumentNotificationOptions.additionalMessage"
                    ></textarea>
                </nz-form-control>
            </nz-form-item>
        </form>
        <nz-alert nzType="error" class="mb-3" *ngIf="showUserRequiredError" nzMessage="Lietotājs jānorāda obligāti"> </nz-alert>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="cancel()">Atcelt</button>
        <button nz-button nzType="primary" (click)="submitAndReturn()">Izsūtīt</button>
    </div>
</nz-spin>
