import { Component, Input } from '@angular/core';
import { ClientsService } from '@lui/core/services/clients.service';
import { Client } from '@lui/shared/models';

@Component({
    selector: 'lui-client-name',
    standalone: false,
    templateUrl: './client-name.component.html',
    styleUrl: './client-name.component.less',
})
export class ClientNameComponent {
    @Input() client: Client;

    constructor(private clientsService: ClientsService) {}

    clientToString(client: Client): string {
        return this.clientsService.toString(client);
    }
}
