import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company, PrintoutImageType } from '@lui/shared/models/company.model';
import { BaseDownloadService } from './base.service';
import { lastValueFrom, Observable } from 'rxjs';
import { FileMetadata } from '@lui/shared/models/file.model';
import { NzImageService } from 'ng-zorro-antd/image';
import { BaseModel, CommandResult } from '@lui/shared/models';

@Injectable({ providedIn: 'root' })
export class CompaniesService extends BaseDownloadService<Company> {
    constructor(httpClient: HttpClient, protected nzImageService: NzImageService) {
        super(httpClient, '/api/v1/companies/', nzImageService);
    }

    async downloadAttachment(companyId: number, imageType: PrintoutImageType): Promise<void> {
        return this.handleFileDownloading(
            lastValueFrom(this.httpClient.get<CommandResult<FileMetadata>>(`${this.baseApiUrl}${companyId}/attachments/${imageType}/`)),
        );
    }

    async deleteAttachment(companyId: number, imageType: PrintoutImageType): Promise<void> {
        return lastValueFrom(this.httpClient.delete<void>(`${this.baseApiUrl}${companyId}/attachments/${imageType}/`));
    }

    addAttachment(companyId: number, formData: FormData, imageType: PrintoutImageType): Promise<boolean> {
        return lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${companyId}/attachments/${imageType}`, formData));
    }

    getAllByUserDepartmentPermission(permission: string): Observable<CommandResult<Company[]>> {
        return this.httpClient.get<CommandResult<Company[]>>(`${this.baseApiUrl}by-user-permission/${permission}`);
    }

    toString(company: Company): string {
        return company.name;
    }
}
