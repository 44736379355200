import { Component } from '@angular/core';
import { Client, Contact } from '@lui/shared/models';
import { BaseClientNotificationConfiguration } from '@lui/shared/models/base-client-notification-configuration.model';
import { WebUser } from '@lui/shared/models/web-user.model';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'lui-client-notification-config-modal',
    standalone: false,
    templateUrl: './client-notification-config-modal.component.html',
    styleUrl: './client-notification-config-modal.component.less',
})
export class ClientNotificationConfigModalComponent {
    loading: boolean = false;
    client: Client;
    contact: Contact;
    clientNotificationConfig: BaseClientNotificationConfiguration;

    constructor(private modal: NzModalRef) {}

    cancel(): void {
        this.modal.close();
    }

    sendNotification(): void {
        if (this.client && this.client.webUsers) {
            this.clientNotificationConfig.webUserIds = this.client.webUsers
                .filter((wu: WebUser): boolean => wu.selected)
                .map((wu: WebUser): number => wu.id);
        }

        this.modal.close(this.clientNotificationConfig);
    }
}
