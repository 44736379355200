import { Directive, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@lui/core/services';
import { Subject } from 'rxjs';
import { DepartmentPermissionQuery } from '../models/department-permission-query.model';

@Directive({
    selector: '[hasDepartmentPermission]',
    standalone: false,
})
export class HasDepartmentPermissionDirective implements OnDestroy, OnChanges {
    private destroy$: Subject<void> = new Subject<void>();

    private hasView: boolean = false;
    private elseTemplate: TemplateRef<any> | null = null;
    permissionQuery: DepartmentPermissionQuery;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

    @Input() set hasDepartmentPermission(permissionQuery: DepartmentPermissionQuery) {
        this.permissionQuery = permissionQuery;
        this.updateView();
    }

    @Input() set hasDepartmentPermissionElse(template: TemplateRef<any> | null) {
        this.elseTemplate = template;
        this.updateView();
    }

    private updateView(): void {
        const can: boolean = this.authService.userHasDepartmentPermission(this.permissionQuery.departmentId, this.permissionQuery.permissions);
        if (can && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!can) {
            this.viewContainer.clear();
            this.hasView = false;

            if (this.elseTemplate) {
                this.viewContainer.createEmbeddedView(this.elseTemplate);
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateView();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
