import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseDownloadServiceWithFilter } from './base.service';
import { Estimate } from '@lui/shared/models/estimate.model';
import { lastValueFrom } from 'rxjs';
import { MapImage } from '@lui/shared/components/map/map-image.model';
import { PrintoutDialogOptions } from '@lui/shared/components/printout-dialog/printout-dialog-options.model';
import { PrintoutDialogComponent } from '@lui/shared/components/printout-dialog/printout-dialog.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingService } from './loading.service';
import { FileMetadata } from '@lui/shared/models/file.model';
import { NzImageService } from 'ng-zorro-antd/image';
import { CommandResult, PrintoutType } from '@lui/shared/models';
import { EstimateFilter } from '@lui/shared/models/estimate-filter.model';
import { CadastreGeom } from '@lui/shared/models/cadastre-geom.model';
import { BaseClientNotificationConfiguration } from '@lui/shared/models/base-client-notification-configuration.model';
import { EstimatesStats } from '@lui/shared/models/estimates-stats-model';

@Injectable({ providedIn: 'root' })
export class EstimateService extends BaseDownloadServiceWithFilter<Estimate, EstimateFilter> {
    constructor(
        httpClient: HttpClient,
        private modalService: NzModalService,
        private msgService: NzMessageService,
        private loadingService: LoadingService,
        protected nzImageService: NzImageService,
    ) {
        super(httpClient, '/api/v1/estimates/', nzImageService);
    }

    async downloadAttachment(estimateId: number, fileId: string, isMapImage: boolean = false): Promise<void> {
        return this.handleFileDownloading(
            lastValueFrom(this.httpClient.get<CommandResult<FileMetadata>>(`${this.baseApiUrl}${estimateId}/${isMapImage}/${fileId}`)),
        );
    }

    async deleteAttachment(estimateId: number, fileId: string, isMapImage: boolean = false): Promise<void> {
        return lastValueFrom(this.httpClient.delete<void>(`${this.baseApiUrl}${estimateId}/${isMapImage}/${fileId}`));
    }

    addAttachment(estimateId: number, formData: FormData): Promise<boolean> {
        return lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${estimateId}/attachments`, formData));
    }

    addMapImage(estimateId: number, mapImage: MapImage): Promise<boolean> {
        return lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${estimateId}/map-images`, mapImage));
    }

    toggleIncludeInPrintout(estimateId: number, attachmentId: number, includeInPrintout: boolean): Promise<boolean> {
        return lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${estimateId}/attachments/${attachmentId}/${includeInPrintout}`, null));
    }

    async downloadPrintout(estimateId: number, printoutOptions: PrintoutDialogOptions): Promise<string> {
        return this.handleFileDownloading(
            lastValueFrom(
                this.httpClient.get<CommandResult<FileMetadata>>(
                    `${this.baseApiUrl}${estimateId}/printout/${printoutOptions.userId}/${printoutOptions.printoutTemplateId}`,
                ),
            ),
        );
    }

    async print(estimate: Estimate): Promise<void> {
        const modal = this.modalService.create({
            nzTitle: 'Izdrukas iestatījumi',
            nzContent: PrintoutDialogComponent,
        });

        modal.afterOpen.subscribe(() => {
            const component = modal.getContentComponent();
            component.printoutType = PrintoutType.Estimate;
            component.orderTypeId = estimate.ordersTypeId;
            component.loadTemplates();
        });

        modal.afterClose.subscribe(async (printoutOptions: PrintoutDialogOptions) => {
            if (printoutOptions) {
                try {
                    this.loadingService.isLoading.next(true);
                    await this.downloadPrintout(estimate.id, printoutOptions);
                    this.loadingService.isLoading.next(false);
                } catch (ex) {
                    this.loadingService.isLoading.next(false);
                    console.error(ex);
                    if (ex.error?.errors) {
                        ex.error.errors.forEach((error) => {
                            this.msgService.error(error);
                        });
                        return;
                    }

                    this.msgService.error('Neizdevās lejupielādēt izdruku!');
                    return;
                }
            }
        });
    }

    async getCadastreGeomsByCadastreNumbers(cadastreNumbers: string): Promise<CommandResult<CadastreGeom[]>> {
        return lastValueFrom(this.httpClient.get<CommandResult<CadastreGeom[]>>(`${this.baseApiUrl}cadastre-geoms/${cadastreNumbers}`));
    }

    async sendEstimateNotification(estimateNotificationConfig: BaseClientNotificationConfiguration): Promise<void> {
        return await lastValueFrom(this.httpClient.post<void>(`${this.baseApiUrl}send-notification`, estimateNotificationConfig));
    }

    async getEstimatesStats(): Promise<CommandResult<EstimatesStats>> {
        return await lastValueFrom(this.httpClient.get<CommandResult<EstimatesStats>>(`${this.baseApiUrl}stats`));
    }
}
