<nz-spin [nzSpinning]="loading">
    <div class="table-container">
        <nz-table #basicTable nzSize="small" [nzData]="document?.documentFiles" [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th class="text-center break-text" nzBreakWord nzWidth="50%">Dokumenta fails</th>
                    <th *ngIf="isEditMode" nzWidth="10%" class="text-center">Klients parakstījis</th>
                    <th *ngIf="isEditMode" nzWidth="10%" class="text-center">Metrum parakstījis</th>
                    <th *ngIf="canEditFields" class="text-center" nzWidth="10%">Klientam jāparaksta</th>
                    <th *ngIf="canEditFields" class="text-center" nzWidth="10%">Metrum jāparaksta</th>
                    <th class="text-center" nzWidth="10%"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container>
                    <tr *ngFor="let documentFile of basicTable.data; let i = index">
                        <td>
                            <strong>{{ documentFile.file.originalName }}</strong>
                        </td>

                        <td *ngIf="isEditMode">
                            {{ documentFile.clientSignedTimestamp ? (documentFile.clientSignedTimestamp | date : dateTimeFormat) : '-' }}
                        </td>

                        <td *ngIf="isEditMode">
                            {{ documentFile.metrumSignedTimestamp ? (documentFile.metrumSignedTimestamp | date : dateTimeFormat) : '-' }}
                        </td>

                        <td *ngIf="canEditFields" class="text-center">
                            <label
                                nz-checkbox
                                [(ngModel)]="documentFile.isSignableByClient"
                                (ngModelChange)="isSignableCheckedChanged(documentFile)"
                                [disabled]="!documentIsSignable"
                            ></label>
                        </td>

                        <td *ngIf="canEditFields" class="text-center">
                            <label
                                nz-checkbox
                                [(ngModel)]="documentFile.isSignableByMetrum"
                                (ngModelChange)="isSignableCheckedChanged(documentFile)"
                                [disabled]="!documentIsSignable"
                            ></label>
                        </td>

                        <td class="text-center">
                            <nz-flex nzGap="small">
                                <ng-container *ngIf="isEditMode">
                                    <ng-container *ngIf="canEditFields; else signColumnForViewer">
                                        <lui-e-sign
                                            [disabled]="!documentFile.isSignableByMetrum"
                                            [file]="documentFile.file"
                                            [signableFileType]="ESignatureSignableFileTypes.DOCUMENT"
                                        ></lui-e-sign>
                                        <button *hasPermission="[GroupPermissions.DOCUMENT_DELETE]" nz-button nzDanger (click)="deleteDocumentFile(i)">
                                            <i nz-icon nzType="delete"></i>
                                        </button>
                                    </ng-container>
                                    <ng-template #signColumnForViewer>
                                        <lui-e-sign
                                            *ngIf="documentFile.isSignableByMetrum"
                                            [file]="documentFile.file"
                                            [signableFileType]="ESignatureSignableFileTypes.DOCUMENT"
                                        ></lui-e-sign>
                                    </ng-template>
                                </ng-container>
                            </nz-flex>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
    </div>
</nz-spin>
