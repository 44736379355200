import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseUpsertComponent } from '@lui/core/components';
import { DocumentsService } from '@lui/core/services/documents.service';
import { Document, DocumentType, ErrorCode, User } from '@lui/shared/models';
import { DocumentFile } from '@lui/shared/models/document-file.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DocumentNotificationToEmployeeModalComponent } from './document-notification-to-employee-modal/document-notification-to-employee-modal.component';
import { UsersService } from '@lui/core/services';
import { EmployeeDocumentNotification } from '@lui/shared/models/employee-document-notification-options.model';
import { DATE_TIME_FORMAT } from '@lui/shared/constants';
import { filter, Observable, Subscription, take } from 'rxjs';
import { GroupPermissionsType } from '@lui/shared/group-permissions-type.enum';
import { DocumentTypesService } from '@lui/core/services/document-types.service';
import moment from 'moment';

@Component({
    selector: 'lui-upsert-document',
    templateUrl: './upsert-document.component.html',
    styleUrl: './upsert-document.component.less',
    standalone: false,
})
export class UpsertDocumentComponent extends BaseUpsertComponent<Document, DocumentsService> implements OnInit {
    GroupPermissions = GroupPermissionsType;
    dateTimeFormat = DATE_TIME_FORMAT;

    documentIsSignable: boolean;

    canEditFields: boolean = false;

    documentTypes$: Observable<DocumentType[]>;

    scontractId: number;
    constructor(
        protected documentsService: DocumentsService,
        protected messageService: NzMessageService,
        private modal: NzModalRef,
        private fb: UntypedFormBuilder,
        private modalService: NzModalService,
        private usersService: UsersService,
        private documentTypesService: DocumentTypesService,
    ) {
        super(documentsService, messageService);

        // set modal texts
        this.saveSuccessText = 'Dokuments saglabāts!';
        this.createObjectSaveErrorText = 'Kļūda pievienojot dokumentu';
        this.updateObjectSaveErrorText = 'Kļūda labojot dokumentu';
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        this.documentTypes$ = await this.documentTypesService.getDocumentTypes();
        this.loading = false;
    }

    createForm(): UntypedFormGroup {
        this.documentIsSignable = false;
        return this.fb.group({
            documentTypeId: [null, [Validators.required]],
            number: [null, [Validators.required, Validators.maxLength(32)]],
            date: [null],
            validUntil: [null],
            notes: [null, [Validators.maxLength(255)]],
            isGenerated: [false],
            isPrintableInContract: [false],
            isPublishableInPortal: [false],
            isPublishableInProfile: [false],
            isSignable: [false],
        });
    }

    createAndFillForm(): UntypedFormGroup {
        this.documentIsSignable = this.originalObject.isSignable;
        return this.fb.group({
            documentTypeId: [this.originalObject.documentTypeId, [Validators.required]],
            number: [this.originalObject.number, [Validators.required, Validators.maxLength(32)]],
            date: [this.originalObject.date ? moment(this.originalObject.date).toDate() : null],
            validUntil: [this.originalObject.date ? moment(this.originalObject.validUntil).toDate() : null],
            notes: [this.originalObject.notes, [Validators.maxLength(255)]],
            isGenerated: [this.originalObject.isGenerated],
            isPrintableInContract: [this.originalObject.isPrintableInContract],
            isPublishableInPortal: [this.originalObject.isPublishableInPortal],
            isPublishableInProfile: [this.originalObject.isPublishableInProfile],
            isSignable: [this.originalObject.isSignable],
        });
    }

    afterInit(): void {
        // check, when document.isSignable changes
        // if set to false- every document_file should also be market as not-signable and the checkboxes for documentFile.isSignable
        // should be inactive (they depend on this.documentIsSignable)
        if (this.isEditMode) {
            this.objectForm.controls['isSignable'].valueChanges.subscribe(async (value: boolean): Promise<void> => {
                this.documentIsSignable = value;

                if (!value) {
                    try {
                        this.loading = true;
                        await Promise.all(
                            this.originalObject.documentFiles
                                .filter((documentFile: DocumentFile): boolean => documentFile.isSignableByClient || documentFile.isSignableByMetrum)
                                .map(async (documentFile: DocumentFile): Promise<void> => {
                                    documentFile.isSignableByClient = false;
                                    documentFile.isSignableByMetrum = false;
                                    if (this.isEditMode) {
                                        await this.documentsService.updateDocumentFile(documentFile);
                                    }
                                }),
                        );
                        this.loading = false;
                    } catch (ex) {
                        this.loading = false;
                        console.error(ex);
                        if (ex.error?.errors) {
                            ex.error.errors.forEach((error) => this.messageService.error(error));
                        } else {
                            this.messageService.error('Kļūda labojot dokumenta failu');
                        }
                    }
                }

                // Only reassign after all document updates are done
                this.originalObject = await (await this.service.getSingle(this.originalObject.id)).data;
            });
        }
    }

    async sendNotificationToEmployee(): Promise<void> {
        try {
            this.loading = true;
            const users: User[] = (await this.usersService.getUsersForDocumentNotification(this.originalObject.id)).data;
            this.loading = false;
            const modal = this.modalService.create({
                nzTitle: 'Paziņojums darbiniekam',
                nzContent: DocumentNotificationToEmployeeModalComponent,
            });

            modal.afterOpen.subscribe(() => {
                modal.getContentComponent().users = users;
            });

            modal.afterClose.subscribe(async (employeeDocumentNotificationOptions: EmployeeDocumentNotification): Promise<void> => {
                if (!employeeDocumentNotificationOptions) {
                    this.messageService.error('Neizdevās nosūtīt atgādinājumu darbiniekam');
                    return;
                }

                employeeDocumentNotificationOptions.documentId = this.originalObject.id;
                this.loading = true;
                await this.documentsService.sendEmployeeDocumentSigningNotification(employeeDocumentNotificationOptions);
                this.messageService.success('Atgādinājums veiksmīgi nosūtīts');
                this.originalObject = await (await this.service.getSingle(this.originalObject.id)).data;
                this.loading = false;
            });
        } catch (ex) {
            this.loading = false;
            console.error(ex);
            if (ex.error?.errors) {
                ex.error.errors.forEach((error) => {
                    this.messageService.error(error);
                });
                return;
            }

            this.messageService.error('Neizdevās nosūtīt atgādinājumu darbiniekam');
            return;
        }
    }

    async save(): Promise<void> {
        const document: Document = <Document>this.objectForm.getRawValue();
        if (this.isEditMode) {
            await this.service.update(
                this.objectId,
                this.originalObject ? Object.assign(this.originalObject, this.objectForm.getRawValue()) : this.objectForm.getRawValue(),
            );
            return;
        } else {
            if (this.scontractId) {
                document.scontractId = this.scontractId;
            }
        }
        this.objectId = await this.documentsService.create(document);
    }

    async validateAndSave(): Promise<void> {
        this.objectForm.statusChanges
            .pipe(
                filter((status) => status !== 'PENDING'),
                take(1),
            )
            .subscribe(async (status) => {
                if (status !== 'VALID') {
                    return;
                }
                try {
                    this.loading = true;
                    if (this.returnObject) {
                        const obj = this.objectForm.getRawValue();
                        obj.id = this.objectId;
                        this.submitAndReturn(obj);
                        return;
                    }
                    await this.save();
                    this.messageService.success(this.saveSuccessText);
                    if (!this.isEditMode) {
                        const newDocument: Document = (await this.documentsService.getSingle(this.objectId)).data;
                        this.setEditMode(newDocument);
                    } else {
                        this.submitAndReturn(true);
                    }

                    this.loading = false;
                    return;
                } catch (ex) {
                    this.loading = false;
                    if (ex.error?.code === ErrorCode.FieldValidation) {
                        this.fieldErrors = ex.error.errors;
                        this.mapErrorsToControls(ex.error.errors);
                    }

                    if (ex.error?.errors) {
                        ex.error.errors.forEach((error) => {
                            this.messageService.error(error);
                        });
                        return;
                    }

                    this.messageService.error(this.isEditMode ? this.updateObjectSaveErrorText : this.createObjectSaveErrorText);
                }
            });

        this.objectForm.markAsDirtyAndUpdateAll();
    }

    beforeDocumentFileUpload = async (file: File) => {
        if (!this.isEditMode) {
            return;
        }

        const documentFileFormData = new FormData();

        documentFileFormData.append('documentId', this.originalObject.id.toString());
        documentFileFormData.append('file', file);

        try {
            this.loading = true;
            await this.documentsService.addDocumentFile(documentFileFormData);
            this.messageService.success('Dokumenta fails pievienots');
            await this.reloadData();
            this.loading = false;
        } catch (ex) {
            this.loading = false;
            console.error(ex);
            if (ex.error?.errors) {
                ex.error.errors.forEach((error) => {
                    this.messageService.error(error);
                });
                return;
            }

            this.messageService.error('Kļūda pievienojot dokumenta failu');
            return;
        }
    };

    submitAndReturn(val: boolean | Document): void {
        this.modal.close(val);
    }

    cancel(): void {
        this.modal.close();
    }

    customUploadRequest = (item: any): Subscription => {
        // Simulate successful upload
        const { onSuccess } = item;

        setTimeout(() => {
            // Notify NG-Zorro that the upload was successful
            if (onSuccess) {
                onSuccess({}, item.file, item.file);
            }
        });

        return new Subscription();
    };
}
