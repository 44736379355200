<nz-form-item>
    <nz-form-label *ngIf="fieldLabel" [nzFor]="fieldId">{{ fieldLabel }}</nz-form-label>
    <nz-form-control>
        <nz-input-group [nzAddOnAfter]="!idMode && (isSearchPerformed || object) ? suffixActions : null" [nzCompact]="true" class="p-0">
            <!-- For returning full object -->
            <nz-select
                *ngIf="!idMode"
                #selectObject
                nzShowSearch
                nzServerSearch
                [nzPlaceHolder]="fieldPlaceholder"
                [(ngModel)]="object"
                [nzShowArrow]="false"
                [ngModelOptions]="{ standalone: true }"
                [nzFilterOption]="nzFilterOption"
                (nzOnSearch)="search($event)"
                (ngModelChange)="objectSelectionChanged($event)"
            >
                <nz-option *ngFor="let o of listOfOption" [nzLabel]="o.text" [nzValue]="o.value"> </nz-option>
            </nz-select>

            <!-- For returning object.id -->
            <nz-select
                *ngIf="idMode"
                #selectId
                nzShowSearch
                nzServerSearch
                nzAllowClear="true"
                [nzPlaceHolder]="fieldPlaceholder"
                [(ngModel)]="id"
                [nzShowArrow]="false"
                [ngModelOptions]="{ standalone: true }"
                [nzFilterOption]="nzFilterOption"
                (nzOnSearch)="search($event)"
                (ngModelChange)="idSelectionChanged($event)"
            >
                <nz-option *ngFor="let o of listOfOption" [nzLabel]="o.text" [nzValue]="o.value.id"> </nz-option>
            </nz-select>
        </nz-input-group>
    </nz-form-control>
    <ng-template #suffixActions>
        <nz-flex nzGap="small">
            <button nz-button [nzSize]="'small'" [nzType]="'primary'" *ngIf="isSearchPerformed" (click)="addObject()">
                <i nz-icon nzType="plus" nzTheme="outline"></i>
            </button>
            <button nz-button [nzType]="'secondary'" [nzSize]="'small'" *ngIf="object" (click)="editObject()">
                <i nz-icon nzType="edit" nzTheme="outline"></i>
            </button>
            <button nz-button nzDanger [nzType]="'secondary'" [nzSize]="'small'" *ngIf="object" (click)="clearSelection()">
                <i nz-icon nzType="minus" nzTheme="outline"></i>
            </button>
        </nz-flex>
    </ng-template>
</nz-form-item>
