<nz-spin [nzSpinning]="loading" *ngIf="objectForm">
    <form nz-form [formGroup]="objectForm" class="login-form">
        <nz-form-item>
            <nz-form-label nzRequired nzFor="personTypeId" [nzSm]="8" [nzXs]="24">Personas tips</nz-form-label>
            <nz-form-control luiValidationError="personTypeId" [nzSm]="16" [nzXs]="24">
                <nz-select nzShowSearch nzPlaceHolder="Personas tips" formControlName="personTypeId">
                    <nz-option *ngFor="let p of personTypes$ | async" [nzLabel]="p.name" [nzValue]="p.originalLuidaisId"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzRequired [nzSm]="8" [nzXs]="24" nzFor="name">Vārds</nz-form-label>
            <nz-form-control luiValidationError="name" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="name" formControlName="name" placeholder="Vārds" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzFor="surname" [nzSm]="8" [nzXs]="24">Uzvārds</nz-form-label>
            <nz-form-control luiValidationError="surname" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="surname" formControlName="surname" placeholder="Uzvārds" />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzFor="phone" [nzSm]="8" [nzXs]="24">Telefons</nz-form-label>
            <nz-form-control luiValidationError="phone" [nzSm]="16" [nzXs]="24">
                <input type="text" nz-input id="phone" formControlName="phone" placeholder="Telefons" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label nzFor="contactEmail" [nzSm]="8" [nzXs]="24">Epasts</nz-form-label>
            <nz-form-control luiValidationError="contactEmail" [nzSm]="16" [nzXs]="24">
                <input type="email" nz-input id="contactEmail" formControlName="contactEmail" placeholder="Epasts" />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="notes">Piezīmes</nz-form-label>
            <nz-form-control [nzSm]="16" [nzXs]="24">
                <textarea rows="5" nz-input id="notes" formControlName="notes" placeholder="Piezīmes"></textarea>
            </nz-form-control>
        </nz-form-item>

        <nz-alert
            nzType="error"
            *ngIf="isSubmitPerformed && this.objectForm?.errors?.atLeastOne"
            nzMessage="Jānorāda e-pasta aderse vai telefona numurs!"
        ></nz-alert>
        <div></div>
        <div *nzModalFooter>
            <button nz-button nzType="default" [disabled]="loading" (click)="cancel()">Atcelt</button>
            <button nz-button nzType="primary" [disabled]="loading" (click)="validateAndSave()">Saglabāt</button>
        </div>
    </form>
</nz-spin>
