import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[draggableUnit]',
    providers: [NgModel],
    standalone: false
})
export class DraggableUnitDirective implements OnInit {

    @Input('draggableUnit')
    placeholderValue: string;

    @Input()
    conditional: boolean;

    constructor(private el: ElementRef<HTMLDivElement>) {
    }

    ngOnInit(): void {
        this.el.nativeElement.setAttribute('draggable', 'true');
        this.el.nativeElement.style.cursor = 'pointer';
        this.el.nativeElement.addEventListener('dragstart', (event: any) => {
            event.dataTransfer.setData('text/html', this.conditional ? this.wrapInTags(this.placeholderValue) : this.placeholderValue);
            event.effectAllowed = 'copy';
        });
    }

    private wrapInTags(val: string): string {
        const tagName = val.replace(/^\[+|\]+$/g, '')

        return `[${tagName}] X [/${tagName}]`;
    }
}
