import { BehaviorSubject, firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmRegistration } from '@lui/auth/confirm-registration/confirm-registration.model';
import { Router } from '@angular/router';
import { LoginModel } from '@lui/auth/login/login.model';
import { DepartmentPermissions } from '@lui/shared/models/department-permissions.model';
import { WorkTimesService } from './work-times.service';

export const AUTH_TOKEN_KEY = 'access_token';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public get decodedToken(): any {
        if (!this.decodedTokenCache) {
            this.decodedTokenCache = this.jwtHelper.decodeToken(this.token);
        }
        return this.decodedTokenCache;
    }

    public get userDepartmentPermissions(): DepartmentPermissions[] {
        if (!this.userDepartmentPermissionsCache && this.decodedToken) {
            this.userDepartmentPermissionsCache = JSON.parse(this.decodedToken.departmentPermissions) || [];
            this.userDepartmentPermissionsMapCache = [];
            this.userDepartmentPermissionsCache.forEach((dp) => {
                this.userDepartmentPermissionsMapCache[dp.departmentId] = dp;
            });
        }
        return this.userDepartmentPermissionsCache;
    }

    public get userDepartmentPermissionsMap(): { [key: number]: DepartmentPermissions } {
        if (!this.userDepartmentPermissionsMapCache && this.userDepartmentPermissions && this.decodedToken) {
            this.userDepartmentPermissionsMapCache = [];
            this.userDepartmentPermissionsCache.forEach((dp) => {
                this.userDepartmentPermissionsMapCache[dp.departmentId] = dp;
            });
        }
        return this.userDepartmentPermissionsMapCache;
    }

    public get token(): string {
        return localStorage.getItem(AUTH_TOKEN_KEY);
    }

    public currentToken: Observable<string>;

    private currentTokenSubject: BehaviorSubject<string>;

    public currentUsersubject = new BehaviorSubject<any>(null);

    private decodedTokenCache;
    private userDepartmentPermissionsCache: DepartmentPermissions[];
    private userDepartmentPermissionsMapCache: { [key: number]: DepartmentPermissions } = {};

    constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router, private workTimesService: WorkTimesService) {
        this.currentTokenSubject = new BehaviorSubject<string>(this.token);
        this.currentToken = this.currentTokenSubject.asObservable();
    }

    public get currentUserChanges(): Observable<any> {
        return this.currentUsersubject.asObservable().pipe(filter((s) => !!s));
    }

    public get currentTokenValue(): string {
        return this.currentTokenSubject.value;
    }

    public getUserId() {
        return this.decodedToken.nameid;
    }

    public get getUserDepartmentId(): number {
        return Number(this.decodedToken.userDepartmentId);
    }

    public get isPerformerBoss(): boolean {
        return this.decodedToken.performerBoss != null;
    }

    public get userPerformerIds(): number[] {
        if (!this.decodedToken.performerIds) {
            return null;
        }

        if (!Array.isArray(this.decodedToken.performerIds)) {
            return new Array(this.decodedToken.performerIds).map((id: string): number => parseInt(id, 10));
        }
        
        return this.decodedToken.performerIds.map((id: string): number => parseInt(id, 10));
    }

    public useHasRole(roles: string[]): boolean {
        if (!this.decodedToken) {
            return false;
        }
        const userRoles = this.decodedToken.roles || [];
        return userRoles.some((role) => roles.includes(role));
    }

    public userHasPermission(permissions: string[]): boolean {
        if (!this.decodedToken) {
            return false;
        }

        const userPermissions = this.decodedToken.permissions || [];
        return permissions.every((permission) => userPermissions.includes(permission));
    }

    public userHasAnyPermission(permissions: string[]): boolean {
        if (!this.decodedToken) {
            return false;
        }

        const userPermissions = this.decodedToken.permissions || [];
        return userPermissions.some((permission) => permissions.includes(permission));
    }

    public userHasDepartmentPermission(departmentId: number, permissions: string[]): boolean {
        if (!this.userDepartmentPermissions || this.userDepartmentPermissions.length == 0) {
            return false;
        }

        const departmentPermissions = this.userDepartmentPermissionsMap[departmentId];
        return permissions.every((permission) => departmentPermissions[permission] == true);
    }

    public userHasAnyDepartmentPermission(departmentId: number, permissions: string[]): boolean {
        if (!this.userDepartmentPermissions || this.userDepartmentPermissions.length == 0) {
            return false;
        }

        const departmentPermissions = this.userDepartmentPermissionsMap[departmentId];
        return permissions.some((permission) => departmentPermissions[permission] == true);
    }

    public userHasPermissionInAnyDepartment(departmentIds: number[], permission: string): boolean {
        if (!this.userDepartmentPermissions || this.userDepartmentPermissions.length == 0) {
            return false;
        }

        return departmentIds.some((departmentId: number): boolean => {
            const departmentPermissions = this.userDepartmentPermissionsMap[departmentId];
            return departmentPermissions && departmentPermissions[permission] === true;
        });
    }

    public get loggedIn(): boolean {
        if (!this.token) {
            return false;
        }
        try {
            return !this.jwtHelper.isTokenExpired(this.token);
        } catch (ex) {
            return false;
        }
    }

    callRefreshToken(): Observable<any> {
        return this.http
            .post(`/api/v1/auth/refresh-token`, {
                token: this.token,
            })
            .pipe(
                map((result: any) => {
                    this.setToken(result.data.token);
                    return result.data.token;
                }),
                catchError((e) => {
                    localStorage.removeItem(AUTH_TOKEN_KEY);
                    this.currentTokenSubject.next(null);
                    this.router.navigate(['auth/login']);
                    return throwError(() => e);
                }),
            );
    }

    async login(loginInfo: LoginModel): Promise<any> {
        const result = <any>await firstValueFrom(this.http.post('/api/v1/auth/login', loginInfo));

        if (result.data.token) {
            this.workTimesService.setActiveWorkTime(null);
            this.setToken(result.data.token);
        }
        return result;
    }

    async requestPassChange(email: string): Promise<void> {
        return await firstValueFrom(this.http.post<void>('/api/v1/auth/request-pass', { email }));
    }

    async changePassword(data: ConfirmRegistration): Promise<void> {
        return await firstValueFrom(this.http.post<void>('/api/v1/auth/change-pass', data));
    }

    async confirmRegistration(data: ConfirmRegistration): Promise<void> {
        return await firstValueFrom(this.http.post<void>('/api/v1/auth/confirm-registration', data));
    }

    async signup(email: string, password: string, password2: string): Promise<any> {
        const result = <any>await firstValueFrom(this.http.post('/api/v1/auth/signup', { email: email, password: password, password2: password2 }));
        if (result.accessToken) {
            this.setToken(result.accessToken);
        }
        return result;
    }

    async logout(): Promise<void> {
        await this.http.delete('/api/v1/auth/sign-out').toPromise();
        localStorage.removeItem(AUTH_TOKEN_KEY);
        this.currentTokenSubject.next(null);
    }

    removeTokenAndRedirect(): void {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        this.router.navigate(['auth/login'], { queryParams: { returnUrl: this.router.url } });
    }

    public setToken(token: string) {
        this.decodedTokenCache = null;
        this.userDepartmentPermissionsCache = null;
        this.userDepartmentPermissionsMapCache = null;
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        this.currentTokenSubject.next(token);
    }
}
