import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommandResult } from '@lui/shared/models';
import { AddressPoint } from '@lui/shared/models/address-point.model';
import { CadastreGeom } from '@lui/shared/models/cadastre-geom.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CadastreInfoService {
    baseApiUrl: string = '/api/v1/cadastre-info/';

    constructor(private httpClient: HttpClient) {}

    async getCadastreGeom(cadastreNumber: string): Promise<CommandResult<CadastreGeom>> {
        return await lastValueFrom(this.httpClient.get<CommandResult<CadastreGeom>>(`${this.baseApiUrl}cadastre-geom/${cadastreNumber}`));
    }

    async getAddressPoints(addressSearchString: string): Promise<CommandResult<AddressPoint[]>> {
        return await lastValueFrom(
            this.httpClient.get<CommandResult<AddressPoint[]>>(`${this.baseApiUrl}address-points`, {
                params: new HttpParams().append('addressSearchString', addressSearchString),
            }),
        );
    }
}
