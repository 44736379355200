import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@lui/core/services/auth.service';
import { PrintoutTemplateService } from '@lui/core/services/printout-template.service';
import { UsersService } from '@lui/core/services/users.service';
import { PrintoutType, User } from '@lui/shared/models';
import { PrintoutTemplate } from '@lui/shared/models/printout-template.model';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { map, Observable } from 'rxjs';
import { PrintoutDialogOptions } from './printout-dialog-options.model';

@Component({
    selector: 'lui-printout-dialog',
    templateUrl: 'printout-dialog.component.html',
    standalone: false
})
export class PrintoutDialogComponent implements OnInit {

    @Input()
    printoutType: PrintoutType;

    @Input()
    orderTypeId: number;

    initialized: boolean;
    loading: boolean;
    users$: Observable<User[]>;
    printoutTemplates: PrintoutTemplate[];
    printoutDialogOptions: PrintoutDialogOptions = {} as PrintoutDialogOptions;

    constructor(private modal: NzModalRef,
        private authService: AuthService,
        private usersService: UsersService,
        private printoutTemplateService: PrintoutTemplateService) { }

    ngOnInit() {
        this.printoutDialogOptions.userId = Number(this.authService.getUserId());
        this.users$ = this.usersService.getAll().pipe(map(c => c.data));
    }

    loadTemplates(): void {
        this.printoutTemplateService.getTemplatesForOrderType(this.printoutType, this.orderTypeId)
            .subscribe(templates => {
                this.printoutTemplates = templates;
                const defaultTemplate = templates.find(t => t.isDefault);
                if (defaultTemplate) {
                    this.printoutDialogOptions.printoutTemplateId = defaultTemplate.id
                } else {
                    this.printoutDialogOptions.printoutTemplateId = templates[0]?.id
                }
            });
        this.initialized = true;
    }

    submitAndReturn(): void {
        this.modal.close(this.printoutDialogOptions);
    }

    cancel(): void {
        this.modal.destroy();
    }

    userToString(user: User): string {
        return this.usersService.toString(user);
    }
}
