<input nz-input placeholder="Meklēt" type="text" class="mb-2" [(ngModel)]="searchText" (ngModelChange)="searchAddress()" />
<nz-table #permissionsTable nzSize="small" [nzData]="filteredAddresses" [nzFrontPagination]="false" [nzScroll]="{ y: '40rem' }">
    <tbody>
        <ng-container *ngFor="let address of filteredAddresses">
            <tr>
                <td class="pointer" (click)="selectAddress(address)">{{ address.fullAddress }}</td>
            </tr>
        </ng-container>
    </tbody>
</nz-table>
<div *nzModalFooter>
    <button nz-button nzType="default" (click)="cancel()">Atcelt</button>
</div>
