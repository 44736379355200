import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDownloadServiceWithFilter } from './base.service';
import { CommandResult, OrdersType } from '@lui/shared/models';
import { lastValueFrom } from 'rxjs';
import { FileMetadata } from '@lui/shared/models/file.model';
import { NzImageService } from 'ng-zorro-antd/image';
import { OrderTypeFilter } from '@lui/shared/models/order-type-filter.model';

@Injectable({ providedIn: 'root' })
export class OrdersTypesService extends BaseDownloadServiceWithFilter<OrdersType, OrderTypeFilter> {
    constructor(httpClient: HttpClient, protected nzImageService: NzImageService) {
        super(httpClient, '/api/v1/orders-types/', nzImageService);
    }

    async downloadAttachment(ordersTypeId: number, fileId: string): Promise<void> {
        return this.handleFileDownloading(
            lastValueFrom(this.httpClient.get<CommandResult<FileMetadata>>(`${this.baseApiUrl}${ordersTypeId}/${fileId}`)),
        );
    }

    async deleteAttachment(ordersTypeId: number, fileId: string): Promise<void> {
        return await lastValueFrom(this.httpClient.delete<void>(`${this.baseApiUrl}${ordersTypeId}/${fileId}`));
    }

    async addAttachment(ordersTypeId: number, formData: FormData): Promise<boolean> {
        return await lastValueFrom(this.httpClient.post<boolean>(`${this.baseApiUrl}${ordersTypeId}/attachments`, formData));
    }

    toString(object: OrdersType): string {
        return object.name;
    }
}
