import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'lui-stop-work-timer-modal',
    templateUrl: './stop-work-timer-modal.component.html',
    styleUrl: './stop-work-timer-modal.component.less',
    standalone: false
})
export class StopWorkTimerModalComponent {
    notes: string;
    constructor(private modal: NzModalRef) {}

    submitAndReturn(): void {
        this.modal.close(this.notes);
    }

    cancel(): void {
        this.modal.destroy();
    }
}
