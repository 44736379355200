import { Injectable } from '@angular/core';
import { BaseDownloadServiceWithFilter } from './base.service';
import { Client, CommandResult, Document, DocumentFilter } from '@lui/shared/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NzImageService } from 'ng-zorro-antd/image';
import { lastValueFrom } from 'rxjs';
import { FileMetadata } from '@lui/shared/models/file.model';
import { DocumentFile } from '@lui/shared/models/document-file.model';
import { EmployeeDocumentNotification } from '@lui/shared/models/employee-document-notification-options.model';
import { BaseClientNotificationConfiguration } from '@lui/shared/models/base-client-notification-configuration.model';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService extends BaseDownloadServiceWithFilter<Document, DocumentFilter> {
    constructor(protected httpClient: HttpClient, protected nzImageService: NzImageService) {
        super(httpClient, '/api/v1/documents/', nzImageService);
    }

    async downloadDocumentFile(downloadDocumentFileParams: HttpParams): Promise<void> {
        return this.handleFileDownloading(
            lastValueFrom(
                this.httpClient.get<CommandResult<FileMetadata>>(`${this.baseApiUrl}download-file`, { params: downloadDocumentFileParams }),
            ),
        );
    }

    async sendDocumentSigningNotification(clientNotificationConfig: BaseClientNotificationConfiguration): Promise<CommandResult<void>> {
        return await lastValueFrom(this.httpClient.post<CommandResult<void>>(`${this.baseApiUrl}send-notification`, clientNotificationConfig));
    }

    async updateDocumentFile(documentFile: DocumentFile): Promise<CommandResult<void>> {
        return await lastValueFrom(this.httpClient.put<CommandResult<void>>(`${this.baseApiUrl}document-file`, documentFile));
    }

    async sendEmployeeDocumentSigningNotification(employeeDocumentNotificationOptions: EmployeeDocumentNotification): Promise<void> {
        return await lastValueFrom(this.httpClient.post<void>(`${this.baseApiUrl}send-employee-notification`, employeeDocumentNotificationOptions));
    }

    async getDocumentClient(documentId: number): Promise<CommandResult<Client>> {
        return await lastValueFrom(this.httpClient.get<CommandResult<Client>>(`${this.baseApiUrl}${documentId}/client`));
    }

    async addDocumentFile(documentFileFormData: FormData): Promise<void> {
        return await lastValueFrom(this.httpClient.post<void>(`${this.baseApiUrl}document-file`, documentFileFormData));
    }

    async deleteDocumentFile(documentId: number, documentFileId: number): Promise<void> {
        return await lastValueFrom(this.httpClient.delete<void>(`${this.baseApiUrl}document-file/${documentId}/${documentFileId}`));
    }
}
