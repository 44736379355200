export enum GroupPermissionsType {
    ADDRESS_VIEW = 'ADDRESS_VIEW',
    ADDRESS_NEW = 'ADDRESS_NEW',
    ADDRESS_EDIT = 'ADDRESS_EDIT',
    ADDRESS_DELETE = 'ADDRESS_DELETE',
    ADDRESS_LIST = 'ADDRESS_LIST',
    INVOICE_VIEW = 'INVOICE_VIEW',
    INVOICE_NEW = 'INVOICE_NEW',
    INVOICE_EDIT = 'INVOICE_EDIT',
    INVOICE_DELETE = 'INVOICE_DELETE',
    INVOICE_LIST = 'INVOICE_LIST',
    INVOICE_PRINT = 'INVOICE_PRINT',
    INVOICE_TYPE_VIEW = 'INVOICE_TYPE_VIEW',
    INVOICE_TYPE_NEW = 'INVOICE_TYPE_NEW',
    INVOICE_TYPE_EDIT = 'INVOICE_TYPE_EDIT',
    INVOICE_TYPE_DELETE = 'INVOICE_TYPE_DELETE',
    INVOICE_TYPE_LIST = 'INVOICE_TYPE_LIST',
    CLIENT_VIEW = 'CLIENT_VIEW',
    CLIENT_NEW = 'CLIENT_NEW',
    CLIENT_EDIT = 'CLIENT_EDIT',
    CLIENT_DELETE = 'CLIENT_DELETE',
    CLIENT_LIST = 'CLIENT_LIST',
    CONSTANT_VIEW = 'CONSTANT_VIEW',
    CONSTANT_NEW = 'CONSTANT_NEW',
    CONSTANT_EDIT = 'CONSTANT_EDIT',
    CONSTANT_DELETE = 'CONSTANT_DELETE',
    CONSTANT_LIST = 'CONSTANT_LIST',
    CONTRACT_VIEW = 'CONTRACT_VIEW',
    CONTRACT_NEW = 'CONTRACT_NEW',
    CONTRACT_EDIT = 'CONTRACT_EDIT',
    CONTRACT_DELETE = 'CONTRACT_DELETE',
    CONTRACT_LIST = 'CONTRACT_LIST',
    CONTRACT_PRINT = 'CONTRACT_PRINT',
    DEPARTMENT_VIEW = 'DEPARTMENT_VIEW',
    DEPARTMENT_NEW = 'DEPARTMENT_NEW',
    DEPARTMENT_EDIT = 'DEPARTMENT_EDIT',
    DEPARTMENT_DELETE = 'DEPARTMENT_DELETE',
    DEPARTMENT_LIST = 'DEPARTMENT_LIST',
    DOCUMENT_TYPE_VIEW = 'DOCUMENT_TYPE_VIEW',
    DOCUMENT_TYPE_NEW = 'DOCUMENT_TYPE_NEW',
    DOCUMENT_TYPE_EDIT = 'DOCUMENT_TYPE_EDIT',
    DOCUMENT_TYPE_DELETE = 'DOCUMENT_TYPE_DELETE',
    DOCUMENT_TYPE_LIST = 'DOCUMENT_TYPE_LIST',
    DOCUMENT_VIEW = 'DOCUMENT_VIEW',
    DOCUMENT_NEW = 'DOCUMENT_NEW',
    DOCUMENT_EDIT = 'DOCUMENT_EDIT',
    DOCUMENT_DELETE = 'DOCUMENT_DELETE',
    DOCUMENT_LIST = 'DOCUMENT_LIST',
    PERFORMER_VIEW = 'PERFORMER_VIEW',
    PERFORMER_NEW = 'PERFORMER_NEW',
    PERFORMER_EDIT = 'PERFORMER_EDIT',
    PERFORMER_DELETE = 'PERFORMER_DELETE',
    PERFORMER_LIST = 'PERFORMER_LIST',
    FILE_VIEW = 'FILE_VIEW',
    FILE_NEW = 'FILE_NEW',
    FILE_EDIT = 'FILE_EDIT',
    FILE_DELETE = 'FILE_DELETE',
    FILE_LIST = 'FILE_LIST',
    GROUPS_VIEW = 'GROUPS_VIEW',
    GROUPS_NEW = 'GROUPS_NEW',
    GROUPS_EDIT = 'GROUPS_EDIT',
    GROUPS_DELETE = 'GROUPS_DELETE',
    GROUPS_LIST = 'GROUPS_LIST',
    PERMISSION_VIEW = 'PERMISSION_VIEW',
    PERMISSION_NEW = 'PERMISSION_NEW',
    PERMISSION_EDIT = 'PERMISSION_EDIT',
    PERMISSION_DELETE = 'PERMISSION_DELETE',
    PERMISSION_LIST = 'PERMISSION_LIST',
    USER_VIEW = 'USER_VIEW',
    USER_EDIT = 'USER_EDIT',
    USER_NEW = 'USER_NEW',
    USER_DELETE = 'USER_DELETE',
    USER_LIST = 'USER_LIST',
    ORDERS_VIEW = 'ORDERS_VIEW',
    ORDERS_NEW = 'ORDERS_NEW',
    ORDERS_EDIT = 'ORDERS_EDIT',
    ORDERS_DELETE = 'ORDERS_DELETE',
    ORDERS_LIST = 'ORDERS_LIST',
    ORDERS_TYPE_VIEW = 'ORDERS_TYPE_VIEW',
    ORDERS_TYPE_NEW = 'ORDERS_TYPE_NEW',
    ORDERS_TYPE_EDIT = 'ORDERS_TYPE_EDIT',
    ORDERS_TYPE_DELETE = 'ORDERS_TYPE_DELETE',
    ORDERS_TYPE_LIST = 'ORDERS_TYPE_LIST',
    PAYMENT_VIEW = 'PAYMENT_VIEW',
    PAYMENT_NEW = 'PAYMENT_NEW',
    PAYMENT_EDIT = 'PAYMENT_EDIT',
    PAYMENT_DELETE = 'PAYMENT_DELETE',
    PAYMENT_LIST = 'PAYMENT_LIST',
    PAYOUT_VIEW = 'PAYOUT_VIEW',
    PAYOUT_NEW = 'PAYOUT_NEW',
    PAYOUT_EDIT = 'PAYOUT_EDIT',
    PAYOUT_DELETE = 'PAYOUT_DELETE',
    PAYOUT_LIST = 'PAYOUT_LIST',
    PAYMENT_TYPE_VIEW = 'PAYMENT_TYPE_VIEW',
    PAYMENT_TYPE_NEW = 'PAYMENT_TYPE_NEW',
    PAYMENT_TYPE_EDIT = 'PAYMENT_TYPE_EDIT',
    PAYMENT_TYPE_DELETE = 'PAYMENT_TYPE_DELETE',
    PAYMENT_TYPE_LIST = 'PAYMENT_TYPE_LIST',
    PERSON_TYPE_VIEW = 'PERSON_TYPE_VIEW',
    PERSON_TYPE_NEW = 'PERSON_TYPE_NEW',
    PERSON_TYPE_EDIT = 'PERSON_TYPE_EDIT',
    PERSON_TYPE_DELETE = 'PERSON_TYPE_DELETE',
    PERSON_TYPE_LIST = 'PERSON_TYPE_LIST',
    POSITION_VIEW = 'POSITION_VIEW',
    POSITION_NEW = 'POSITION_NEW',
    POSITION_EDIT = 'POSITION_EDIT',
    POSITION_DELETE = 'POSITION_DELETE',
    POSITION_LIST = 'POSITION_LIST',
    ADMINISTRATIVE_TERRITORY_VIEW = 'ADMINISTRATIVE_TERRITORY_VIEW',
    ADMINISTRATIVE_TERRITORY_NEW = 'ADMINISTRATIVE_TERRITORY_NEW',
    ADMINISTRATIVE_TERRITORY_EDIT = 'ADMINISTRATIVE_TERRITORY_EDIT',
    ADMINISTRATIVE_TERRITORY_DELETE = 'ADMINISTRATIVE_TERRITORY_DELETE',
    ADMINISTRATIVE_TERRITORY_LIST = 'ADMINISTRATIVE_TERRITORY_LIST',
    WORK_TYPE_VIEW = 'WORK_TYPE_VIEW',
    WORK_TYPE_NEW = 'WORK_TYPE_NEW',
    WORK_TYPE_EDIT = 'WORK_TYPE_EDIT',
    WORK_TYPE_DELETE = 'WORK_TYPE_DELETE',
    WORK_TYPE_LIST = 'WORK_TYPE_LIST',
    WORK_VIEW = 'WORK_VIEW',
    WORK_NEW = 'WORK_NEW',
    WORK_EDIT = 'WORK_EDIT',
    WORK_DELETE = 'WORK_DELETE',
    WORK_LIST = 'WORK_LIST',
    SCONTRACT_VIEW = 'SCONTRACT_VIEW',
    SCONTRACT_NEW = 'SCONTRACT_NEW',
    SCONTRACT_EDIT = 'SCONTRACT_EDIT',
    SCONTRACT_DELETE = 'SCONTRACT_DELETE',
    SCONTRACT_LIST = 'SCONTRACT_LIST',
    SCONTRACT_PRINT = 'SCONTRACT_PRINT',
    SCONTRACT_TYPE_VIEW = 'SCONTRACT_TYPE_VIEW',
    SCONTRACT_TYPE_NEW = 'SCONTRACT_TYPE_NEW',
    SCONTRACT_TYPE_EDIT = 'SCONTRACT_TYPE_EDIT',
    SCONTRACT_TYPE_DELETE = 'SCONTRACT_TYPE_DELETE',
    SCONTRACT_TYPE_LIST = 'SCONTRACT_TYPE_LIST',
    BANK_VIEW = 'BANK_VIEW',
    BANK_NEW = 'BANK_NEW',
    BANK_EDIT = 'BANK_EDIT',
    BANK_DELETE = 'BANK_DELETE',
    BANK_LIST = 'BANK_LIST',
    ACCOUNT_VIEW = 'ACCOUNT_VIEW',
    ACCOUNT_NEW = 'ACCOUNT_NEW',
    ACCOUNT_EDIT = 'ACCOUNT_EDIT',
    ACCOUNT_DELETE = 'ACCOUNT_DELETE',
    ACCOUNT_LIST = 'ACCOUNT_LIST',
    FUEL_TYPE_VIEW = 'FUEL_TYPE_VIEW',
    FUEL_TYPE_NEW = 'FUEL_TYPE_NEW',
    FUEL_TYPE_EDIT = 'FUEL_TYPE_EDIT',
    FUEL_TYPE_DELETE = 'FUEL_TYPE_DELETE',
    FUEL_TYPE_LIST = 'FUEL_TYPE_LIST',
    CARD_SERVICE_VIEW = 'CARD_SERVICE_VIEW',
    CARD_SERVICE_NEW = 'CARD_SERVICE_NEW',
    CARD_SERVICE_EDIT = 'CARD_SERVICE_EDIT',
    CARD_SERVICE_DELETE = 'CARD_SERVICE_DELETE',
    CARD_SERVICE_LIST = 'CARD_SERVICE_LIST',
    ROUTE_TYPE_VIEW = 'ROUTE_TYPE_VIEW',
    ROUTE_TYPE_NEW = 'ROUTE_TYPE_NEW',
    ROUTE_TYPE_EDIT = 'ROUTE_TYPE_EDIT',
    ROUTE_TYPE_DELETE = 'ROUTE_TYPE_DELETE',
    ROUTE_TYPE_LIST = 'ROUTE_TYPE_LIST',
    CARD_VIEW = 'CARD_VIEW',
    CARD_NEW = 'CARD_NEW',
    CARD_EDIT = 'CARD_EDIT',
    CARD_DELETE = 'CARD_DELETE',
    CARD_LIST = 'CARD_LIST',
    CAR_VIEW = 'CAR_VIEW',
    CAR_NEW = 'CAR_NEW',
    CAR_EDIT = 'CAR_EDIT',
    CAR_DELETE = 'CAR_DELETE',
    CAR_LIST = 'CAR_LIST',
    ROUTE_VIEW = 'ROUTE_VIEW',
    ROUTE_NEW = 'ROUTE_NEW',
    ROUTE_EDIT = 'ROUTE_EDIT',
    ROUTE_DELETE = 'ROUTE_DELETE',
    ROUTE_LIST = 'ROUTE_LIST',
    FUEL_RECEIPT_VIEW = 'FUEL_RECEIPT_VIEW',
    FUEL_RECEIPT_NEW = 'FUEL_RECEIPT_NEW',
    FUEL_RECEIPT_EDIT = 'FUEL_RECEIPT_EDIT',
    FUEL_RECEIPT_DELETE = 'FUEL_RECEIPT_DELETE',
    FUEL_RECEIPT_LIST = 'FUEL_RECEIPT_LIST',
    PRODUCT_RECEIPT_VIEW = 'PRODUCT_RECEIPT_VIEW',
    PRODUCT_RECEIPT_NEW = 'PRODUCT_RECEIPT_NEW',
    PRODUCT_RECEIPT_EDIT = 'PRODUCT_RECEIPT_EDIT',
    PRODUCT_RECEIPT_DELETE = 'PRODUCT_RECEIPT_DELETE',
    PRODUCT_RECEIPT_LIST = 'PRODUCT_RECEIPT_LIST',
    FUEL_REPORT_VIEW = 'FUEL_REPORT_VIEW',
    FUEL_REPORT_NEW = 'FUEL_REPORT_NEW',
    FUEL_REPORT_EDIT = 'FUEL_REPORT_EDIT',
    FUEL_REPORT_DELETE = 'FUEL_REPORT_DELETE',
    FUEL_REPORT_LIST = 'FUEL_REPORT_LIST',
    FUEL_REPORT_PRINT = 'FUEL_REPORT_PRINT',
    ESTIMATE_VIEW = 'ESTIMATE_VIEW',
    ESTIMATE_NEW = 'ESTIMATE_NEW',
    ESTIMATE_EDIT = 'ESTIMATE_EDIT',
    ESTIMATE_DELETE = 'ESTIMATE_DELETE',
    ESTIMATE_LIST = 'ESTIMATE_LIST',
    ESTIMATE_PRINT = 'ESTIMATE_PRINT',
    ESTIMATE_POSITION_VIEW = 'ESTIMATE_POSITION_VIEW',
    ESTIMATE_POSITION_NEW = 'ESTIMATE_POSITION_NEW',
    ESTIMATE_POSITION_EDIT = 'ESTIMATE_POSITION_EDIT',
    ESTIMATE_POSITION_DELETE = 'ESTIMATE_POSITION_DELETE',
    ESTIMATE_POSITION_LIST = 'ESTIMATE_POSITION_LIST',
    ESTIMATE_POSITION_TYPE_VIEW = 'ESTIMATE_POSITION_TYPE_VIEW',
    ESTIMATE_POSITION_TYPE_NEW = 'ESTIMATE_POSITION_TYPE_NEW',
    ESTIMATE_POSITION_TYPE_EDIT = 'ESTIMATE_POSITION_TYPE_EDIT',
    ESTIMATE_POSITION_TYPE_DELETE = 'ESTIMATE_POSITION_TYPE_DELETE',
    ESTIMATE_POSITION_TYPE_LIST = 'ESTIMATE_POSITION_TYPE_LIST',
    ESTIMATE_POSITION_GROUP_VIEW = 'ESTIMATE_POSITION_GROUP_VIEW',
    ESTIMATE_POSITION_GROUP_NEW = 'ESTIMATE_POSITION_GROUP_NEW',
    ESTIMATE_POSITION_GROUP_EDIT = 'ESTIMATE_POSITION_GROUP_EDIT',
    ESTIMATE_POSITION_GROUP_DELETE = 'ESTIMATE_POSITION_GROUP_DELETE',
    ESTIMATE_POSITION_GROUP_LIST = 'ESTIMATE_POSITION_GROUP_LIST',
    VZD_INVOICE_VIEW = 'VZD_INVOICE_VIEW',
    VZD_INVOICE_NEW = 'VZD_INVOICE_NEW',
    VZD_INVOICE_EDIT = 'VZD_INVOICE_EDIT',
    VZD_INVOICE_DELETE = 'VZD_INVOICE_DELETE',
    VZD_INVOICE_LIST = 'VZD_INVOICE_LIST',
    VZD_INVOICE_TYPE_VIEW = 'VZD_INVOICE_TYPE_VIEW',
    VZD_INVOICE_TYPE_NEW = 'VZD_INVOICE_TYPE_NEW',
    VZD_INVOICE_TYPE_EDIT = 'VZD_INVOICE_TYPE_EDIT',
    VZD_INVOICE_TYPE_DELETE = 'VZD_INVOICE_TYPE_DELETE',
    VZD_INVOICE_TYPE_LIST = 'VZD_INVOICE_TYPE_LIST',
    CLIENT_TYPE_VIEW = 'CLIENT_TYPE_VIEW',
    CLIENT_TYPE_NEW = 'CLIENT_TYPE_NEW',
    CLIENT_TYPE_EDIT = 'CLIENT_TYPE_EDIT',
    CLIENT_TYPE_DELETE = 'CLIENT_TYPE_DELETE',
    CLIENT_TYPE_LIST = 'CLIENT_TYPE_LIST',
    VAT_TYPE_VIEW = 'VAT_TYPE_VIEW',
    VAT_TYPE_NEW = 'VAT_TYPE_NEW',
    VAT_TYPE_EDIT = 'VAT_TYPE_EDIT',
    VAT_TYPE_DELETE = 'VAT_TYPE_DELETE',
    VAT_TYPE_LIST = 'VAT_TYPE_LIST',
    CLIENT_POSITION_VIEW = 'CLIENT_POSITION_VIEW',
    CLIENT_POSITION_NEW = 'CLIENT_POSITION_NEW',
    CLIENT_POSITION_EDIT = 'CLIENT_POSITION_EDIT',
    CLIENT_POSITION_DELETE = 'CLIENT_POSITION_DELETE',
    CLIENT_POSITION_LIST = 'CLIENT_POSITION_LIST',
    SCONTRACT_CLIENT_VIEW = 'SCONTRACT_CLIENT_VIEW',
    SCONTRACT_CLIENT_NEW = 'SCONTRACT_CLIENT_NEW',
    SCONTRACT_CLIENT_EDIT = 'SCONTRACT_CLIENT_EDIT',
    SCONTRACT_CLIENT_DELETE = 'SCONTRACT_CLIENT_DELETE',
    SCONTRACT_CLIENT_LIST = 'SCONTRACT_CLIENT_LIST',
    SCONTRACT_ORDERS_TYPE_VIEW = 'SCONTRACT_ORDERS_TYPE_VIEW',
    SCONTRACT_ORDERS_TYPE_NEW = 'SCONTRACT_ORDERS_TYPE_NEW',
    SCONTRACT_ORDERS_TYPE_EDIT = 'SCONTRACT_ORDERS_TYPE_EDIT',
    SCONTRACT_ORDERS_TYPE_DELETE = 'SCONTRACT_ORDERS_TYPE_DELETE',
    SCONTRACT_ORDERS_TYPE_LIST = 'SCONTRACT_ORDERS_TYPE_LIST',
    PHASE_TYPE_VIEW = 'PHASE_TYPE_VIEW',
    PHASE_TYPE_NEW = 'PHASE_TYPE_NEW',
    PHASE_TYPE_EDIT = 'PHASE_TYPE_EDIT',
    PHASE_TYPE_DELETE = 'PHASE_TYPE_DELETE',
    PHASE_TYPE_LIST = 'PHASE_TYPE_LIST',
    PHASE_VIEW = 'PHASE_VIEW',
    PHASE_NEW = 'PHASE_NEW',
    PHASE_EDIT = 'PHASE_EDIT',
    PHASE_DELETE = 'PHASE_DELETE',
    PHASE_LIST = 'PHASE_LIST',
    CHECKLIST_TYPE_VIEW = 'CHECKLIST_TYPE_VIEW',
    CHECKLIST_TYPE_NEW = 'CHECKLIST_TYPE_NEW',
    CHECKLIST_TYPE_EDIT = 'CHECKLIST_TYPE_EDIT',
    CHECKLIST_TYPE_DELETE = 'CHECKLIST_TYPE_DELETE',
    CHECKLIST_TYPE_LIST = 'CHECKLIST_TYPE_LIST',
    CHECKLIST_POSITION_TYPE_VIEW = 'CHECKLIST_POSITION_TYPE_VIEW',
    CHECKLIST_POSITION_TYPE_NEW = 'CHECKLIST_POSITION_TYPE_NEW',
    CHECKLIST_POSITION_TYPE_EDIT = 'CHECKLIST_POSITION_TYPE_EDIT',
    CHECKLIST_POSITION_TYPE_DELETE = 'CHECKLIST_POSITION_TYPE_DELETE',
    CHECKLIST_POSITION_TYPE_LIST = 'CHECKLIST_POSITION_TYPE_LIST',
    CHECKLIST_VIEW = 'CHECKLIST_VIEW',
    CHECKLIST_NEW = 'CHECKLIST_NEW',
    CHECKLIST_EDIT = 'CHECKLIST_EDIT',
    CHECKLIST_DELETE = 'CHECKLIST_DELETE',
    CHECKLIST_LIST = 'CHECKLIST_LIST',
    CHECKLIST_POSITION_VIEW = 'CHECKLIST_POSITION_VIEW',
    CHECKLIST_POSITION_NEW = 'CHECKLIST_POSITION_NEW',
    CHECKLIST_POSITION_EDIT = 'CHECKLIST_POSITION_EDIT',
    CHECKLIST_POSITION_DELETE = 'CHECKLIST_POSITION_DELETE',
    CHECKLIST_POSITION_LIST = 'CHECKLIST_POSITION_LIST',
    CRM_EVENT_VIEW = 'CRM_EVENT_VIEW',
    CRM_EVENT_NEW = 'CRM_EVENT_NEW',
    CRM_EVENT_EDIT = 'CRM_EVENT_EDIT',
    CRM_EVENT_DELETE = 'CRM_EVENT_DELETE',
    CRM_EVENT_LIST = 'CRM_EVENT_LIST',
    CORRESPONDENCE_MAIL_VIEW = 'CORRESPONDENCE_MAIL_VIEW',
    CORRESPONDENCE_MAIL_NEW = 'CORRESPONDENCE_MAIL_NEW',
    CORRESPONDENCE_MAIL_EDIT = 'CORRESPONDENCE_MAIL_EDIT',
    CORRESPONDENCE_MAIL_DELETE = 'CORRESPONDENCE_MAIL_DELETE',
    CORRESPONDENCE_MAIL_LIST = 'CORRESPONDENCE_MAIL_LIST',
    CORRESPONDENCE_CLIENT_VIEW = 'CORRESPONDENCE_CLIENT_VIEW',
    CORRESPONDENCE_CLIENT_NEW = 'CORRESPONDENCE_CLIENT_NEW',
    CORRESPONDENCE_CLIENT_EDIT = 'CORRESPONDENCE_CLIENT_EDIT',
    CORRESPONDENCE_CLIENT_DELETE = 'CORRESPONDENCE_CLIENT_DELETE',
    CORRESPONDENCE_CLIENT_LIST = 'CORRESPONDENCE_CLIENT_LIST',
    DELIVERABLE_VIEW = 'DELIVERABLE_VIEW',
    DELIVERABLE_NEW = 'DELIVERABLE_NEW',
    DELIVERABLE_EDIT = 'DELIVERABLE_EDIT',
    DELIVERABLE_DELETE = 'DELIVERABLE_DELETE',
    DELIVERABLE_LIST = 'DELIVERABLE_LIST',
    DELIVERABLE_ITEM_VIEW = 'DELIVERABLE_ITEM_VIEW',
    DELIVERABLE_ITEM_NEW = 'DELIVERABLE_ITEM_NEW',
    DELIVERABLE_ITEM_EDIT = 'DELIVERABLE_ITEM_EDIT',
    DELIVERABLE_ITEM_DELETE = 'DELIVERABLE_ITEM_DELETE',
    DELIVERABLE_ITEM_LIST = 'DELIVERABLE_ITEM_LIST',
    DELIVERABLE_ITEM_TYPE_VIEW = 'DELIVERABLE_ITEM_TYPE_VIEW',
    DELIVERABLE_ITEM_TYPE_NEW = 'DELIVERABLE_ITEM_TYPE_NEW',
    DELIVERABLE_ITEM_TYPE_EDIT = 'DELIVERABLE_ITEM_TYPE_EDIT',
    DELIVERABLE_ITEM_TYPE_DELETE = 'DELIVERABLE_ITEM_TYPE_DELETE',
    DELIVERABLE_ITEM_TYPE_LIST = 'DELIVERABLE_ITEM_TYPE_LIST',
    SINVOICE_VIEW = 'SINVOICE_VIEW',
    SINVOICE_NEW = 'SINVOICE_NEW',
    SINVOICE_EDIT = 'SINVOICE_EDIT',
    SINVOICE_DELETE = 'SINVOICE_DELETE',
    SINVOICE_LIST = 'SINVOICE_LIST',
    SINVOICE_PRINT = 'SINVOICE_PRINT',
    WEB_USER_VIEW = 'WEB_USER_VIEW',
    WEB_USER_NEW = 'WEB_USER_NEW',
    WEB_USER_EDIT = 'WEB_USER_EDIT',
    WEB_USER_DELETE = 'WEB_USER_DELETE',
    WEB_USER_LIST = 'WEB_USER_LIST',
    ATTRIBUTE_TYPE_VIEW = 'ATTRIBUTE_TYPE_VIEW',
    ATTRIBUTE_TYPE_NEW = 'ATTRIBUTE_TYPE_NEW',
    ATTRIBUTE_TYPE_EDIT = 'ATTRIBUTE_TYPE_EDIT',
    ATTRIBUTE_TYPE_DELETE = 'ATTRIBUTE_TYPE_DELETE',
    ATTRIBUTE_TYPE_LIST = 'ATTRIBUTE_TYPE_LIST',
    VACATION_TYPE_VIEW = 'VACATION_TYPE_VIEW',
    VACATION_TYPE_NEW = 'VACATION_TYPE_NEW',
    VACATION_TYPE_EDIT = 'VACATION_TYPE_EDIT',
    VACATION_TYPE_DELETE = 'VACATION_TYPE_DELETE',
    VACATION_TYPE_LIST = 'VACATION_TYPE_LIST',
    VACATION_VIEW = 'VACATION_VIEW',
    VACATION_NEW = 'VACATION_NEW',
    VACATION_EDIT = 'VACATION_EDIT',
    VACATION_DELETE = 'VACATION_DELETE',
    VACATION_LIST = 'VACATION_LIST',
    PERFORMER_DOCUMENT_VIEW = 'PERFORMER_DOCUMENT_VIEW',
    PERFORMER_DOCUMENT_NEW = 'PERFORMER_DOCUMENT_NEW',
    PERFORMER_DOCUMENT_EDIT = 'PERFORMER_DOCUMENT_EDIT',
    PERFORMER_DOCUMENT_DELETE = 'PERFORMER_DOCUMENT_DELETE',
    PERFORMER_DOCUMENT_LIST = 'PERFORMER_DOCUMENT_LIST',
    WORK_PERFORMER_VIEW = 'WORK_PERFORMER_VIEW',
    WORK_PERFORMER_NEW = 'WORK_PERFORMER_NEW',
    WORK_PERFORMER_EDIT = 'WORK_PERFORMER_EDIT',
    WORK_PERFORMER_DELETE = 'WORK_PERFORMER_DELETE',
    WORK_PERFORMER_LIST = 'WORK_PERFORMER_LIST',
    CLASSIFIER_VIEW = 'CLASSIFIER_VIEW',
    CLASSIFIER_NEW = 'CLASSIFIER_NEW',
    CLASSIFIER_EDIT = 'CLASSIFIER_EDIT',
    CLASSIFIER_DELETE = 'CLASSIFIER_DELETE',
    CLASSIFIER_LIST = 'CLASSIFIER_LIST',
    MENU_MAIN = 'MENU_MAIN',
    MENU_BOOKKEEPER = 'MENU_BOOKKEEPER',
    MENU_SEARCH = 'MENU_SEARCH',
    MENU_REPORT = 'MENU_REPORT',
    MENU_ADMIN = 'MENU_ADMIN',
    DEBUG = 'DEBUG',
    COMPANY_VIEW = 'COMPANY_VIEW',
    COMPANY_NEW = 'COMPANY_NEW',
    COMPANY_EDIT = 'COMPANY_EDIT',
    COMPANY_DELETE = 'COMPANY_DELETE',
    COMPANY_LIST = 'COMPANY_LIST',
    PAYOUT_TYPE_VIEW = 'PAYOUT_TYPE_VIEW',
    PAYOUT_TYPE_NEW = 'PAYOUT_TYPE_NEW',
    PAYOUT_TYPE_EDIT = 'PAYOUT_TYPE_EDIT',
    PAYOUT_TYPE_DELETE = 'PAYOUT_TYPE_DELETE',
    PAYOUT_TYPE_LIST = 'PAYOUT_TYPE_LIST',
    CONTACT_VIEW = 'CONTACT_VIEW',
    CONTACT_NEW = 'CONTACT_NEW',
    CONTACT_EDIT = 'CONTACT_EDIT',
    CONTACT_DELETE = 'CONTACT_DELETE',
    CONTACT_LIST = 'CONTACT_LIST',
    BASE_LAYER_VIEW = 'BASE_LAYER_VIEW',
    BASE_LAYER_NEW = 'BASE_LAYER_NEW',
    BASE_LAYER_EDIT = 'BASE_LAYER_EDIT',
    BASE_LAYER_DELETE = 'BASE_LAYER_DELETE',
    BASE_LAYER_LIST = 'BASE_LAYER_LIST',
    PRINTOUT_TEMPLATE_VIEW = 'PRINTOUT_TEMPLATE_VIEW',
    PRINTOUT_TEMPLATE_NEW = 'PRINTOUT_TEMPLATE_NEW',
    PRINTOUT_TEMPLATE_EDIT = 'PRINTOUT_TEMPLATE_EDIT',
    PRINTOUT_TEMPLATE_DELETE = 'PRINTOUT_TEMPLATE_DELETE',
    PRINTOUT_TEMPLATE_LIST = 'PRINTOUT_TEMPLATE_LIST',
    GROUP_PERMISSION_DELETE = 'GROUP_PERMISSION_DELETE',
    GROUP_PERMISSION_EDIT = 'GROUP_PERMISSION_EDIT',
    GROUP_PERMISSION_LIST = 'GROUP_PERMISSION_LIST',
    GROUP_PERMISSION_NEW = 'GROUP_PERMISSION_NEW',
    GROUP_PERMISSION_VIEW = 'GROUP_PERMISSION_VIEW',
    WEB_TERMS_CONDITIONS_VIEW = 'WEB_TERMS_CONDITIONS_VIEW',
    WEB_TERMS_CONDITIONS_NEW = 'WEB_TERMS_CONDITIONS_NEW',
    WEB_TERMS_CONDITIONS_EDIT = 'WEB_TERMS_CONDITIONS_EDIT',
    WEB_TERMS_CONDITIONS_DELETE = 'WEB_TERMS_CONDITIONS_DELETE',
    WEB_TERMS_CONDITIONS_LIST = 'WEB_TERMS_CONDITIONS_LIST',
    WORK_TIME_VIEW = 'WORK_TIME_VIEW',
    WORK_TIME_NEW = 'WORK_TIME_NEW',
    WORK_TIME_EDIT = 'WORK_TIME_EDIT',
    WORK_TIME_DELETE = 'WORK_TIME_DELETE',
    WORK_TIME_LIST = 'WORK_TIME_LIST',
    WORK_TIME_ADMIN = 'WORK_TIME_ADMIN',
    WORK_TIME_REPORT = 'WORK_TIME_REPORT',
    LOG_VIEW = 'LOG_VIEW',
    LOG_LIST = 'LOG_LIST',
    COMPARISON_ACT_VIEW = 'COMPARISON_ACT_VIEW',
    COMPARISON_ACT_NEW = 'COMPARISON_ACT_NEW',
    COMPARISON_ACT_EDIT = 'COMPARISON_ACT_EDIT',
    COMPARISON_ACT_DELETE = 'COMPARISON_ACT_DELETE',
    COMPARISON_ACT_LIST = 'COMPARISON_ACT_LIST',
}
