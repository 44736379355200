import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import Map from 'ol/Map';

import { MapMeassureService } from './map.meassure.service';
import { MapService } from './map.service';

@Component({
    selector: 'lui-map',
    templateUrl: 'map.component.html',
    styleUrls: ['map.component.less'],
    standalone: false,
})
export class MapComponent implements OnInit, OnDestroy {
    @Input() map: Map;
    @Output() mapChange: EventEmitter<Map> = new EventEmitter<Map>();

    constructor(private mapService: MapService, private mapMeasureService: MapMeassureService) {}

    async ngOnInit(): Promise<void> {
        await this.mapService.initMap();
        this.map = this.mapService.map;
        this.mapChange.emit(this.map);
    }

    ngOnDestroy(): void {
        this.mapMeasureService.disable(true);
    }
}
