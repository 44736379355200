import { Injectable, Injector } from '@angular/core';
import { BaseModel, EntityType } from '@lui/shared/models';
import { BaseService } from './base.service';
import { ClientsService } from './clients.service';
import { ContactsService } from './contacts.service';
import { CompaniesService } from './companies.service';
import { OrdersTypesService } from './orders-types.service';
import { WorkTypesService } from './work-types.service';
import { DepartmentsService } from './departments.service';
import { PayoutTypesService } from './payout-types.service';
import { PerformersService } from './performers.service';

@Injectable({ providedIn: 'root' })
export class ServiceFactory {
    private serviceMap: { type: EntityType; serviceType: any }[] = [
        { type: EntityType.Client, serviceType: ClientsService },
        { type: EntityType.Contact, serviceType: ContactsService },
        { type: EntityType.Company, serviceType: CompaniesService },
        { type: EntityType.OrderType, serviceType: OrdersTypesService },
        { type: EntityType.WorkType, serviceType: WorkTypesService },
        { type: EntityType.Department, serviceType: DepartmentsService },
        { type: EntityType.PayoutType, serviceType: PayoutTypesService },
        { type: EntityType.Performer, serviceType: PerformersService },
    ];

    constructor(private injector: Injector) {}

    getService<T extends BaseModel>(modelType: EntityType): BaseService<T> {
        const serviceType = this.serviceMap.find((sm) => sm.type === modelType);
        if (!serviceType) {
            throw new Error(`Service for ModelType ${modelType} was not found`);
        }
        return this.injector.get<BaseService<T>>(serviceType.serviceType);
    }
}
