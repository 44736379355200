import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Department, DepartmentGroup } from '@lui/shared/models/department.model';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'lui-department-select',
    templateUrl: './department-select.component.html',
    standalone: false
})
export class DepartmentSelectComponent implements OnInit {

    @Input()
    departmentGroups: DepartmentGroup[];

    @Input()
    formGroup: UntypedFormGroup;

    @Input()
    limitedSizes: boolean = false;

    @Output()
    ngModelChange: EventEmitter<Department> = new EventEmitter<Department>();

    constructor() { }

    ngOnInit() { }

    departmentChanged(departmentId: number): void {
        const department = (<any>(this.departmentGroups.map(dg => dg.departments))).flat().find(d => d.id === departmentId);
        this.ngModelChange.emit(department);
    }
}
