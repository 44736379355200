import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ESignatureService } from '@lui/core/services/e-signature.service';
import { ESignFlow } from '@lui/shared/models/e-sign-flow.model';
import { ESignatureFlow } from '@lui/shared/models/e-signature-flow.enum';
import { ESignatureLocalStorageKeys } from '@lui/shared/models/e-signature-local-storage-keys.enum';
import { ESignatureStep } from '@lui/shared/models/e-signature-step.enum';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'lui-e-sign-flow-select',
    templateUrl: './e-sign-flow-select.component.html',
    styleUrl: './e-sign-flow-select.component.less',
    standalone: false
})
export class ESignFlowSelectComponent {
    fileId: number;
    signableFileType: string;

    availableFlows: ESignFlow[] = [
        {
            title: 'eParaksts Mobile',
            imgLocation: '../../../../../assets/img/e-sign/eMobile.png',
            acrValue: ESignatureFlow.MOBILE,
        },
        {
            title: 'eID karte vai eParaksts karte',
            imgLocation: '../../../../../assets/img/e-sign/eparaksts_eID_Karte.png',
            acrValue: ESignatureFlow.CARD,
        },
    ];

    constructor(private router: Router, private eSignatureService: ESignatureService, private msgService: NzMessageService, private modal: NzModalRef) {}

    async selectFlow(flow: ESignFlow): Promise<void> {
        const params = new HttpParams()
            .append('acrValue', flow.acrValue)
            .append('fileId', this.fileId.toString())
            .append('returnRoute', this.router.url)
            .append('signableFileType', this.signableFileType);
        localStorage.setItem(ESignatureLocalStorageKeys.RETURN_ROUTE, this.router.url);
        localStorage.setItem(ESignatureLocalStorageKeys.FLOW, flow.acrValue);
        localStorage.setItem(ESignatureLocalStorageKeys.STEP, ESignatureStep.INIT);

        try {
            const url = (await this.eSignatureService.getSigningFlowUrl(params)).data;
            window.open(url, '_self');
        } catch (ex) {
            this.eSignatureService.clearESignatureLocalStorageValues();
            console.error(ex);
            if (ex.error?.errors) {
                ex.error.errors.forEach((error) => {
                    this.msgService.error(error);
                });
                this.modal.destroy();
                return;
            }

            this.msgService.error(`Neizdevās uzsākt parakstīšanu`);
            this.modal.destroy();
            return;
        }
    }
}
