import { Injectable } from '@angular/core';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import * as olObservable from 'ol/Observable';
import olDraw from 'ol/interaction/Draw';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';
import wkt from 'ol/format/WKT.js';
import { BehaviorSubject } from 'rxjs';
import Overlay from 'ol/Overlay';
import * as olSphere from 'ol/sphere';
import { Type } from 'ol/geom/Geometry';

@Injectable({
    providedIn: 'root'
})
export class MapMeassureService {

    map: Map;
    sketch: Feature<any>;
    measureTooltipElement: HTMLElement;
    measureTooltip: Overlay;
    continuePolygonMsg: string = 'Click to continue drawing the polygon';
    measureSource: VectorSource<any>;
    measureLayer: VectorLayer<any>;
    pointermoveEvent: {};
    mouseoutEvent: {};
    drawStartEvent: {};
    drawEndEvent: {};
    drawInteraction: olDraw;

    drawingFinished = new BehaviorSubject<string>(null);

    showMeassureTooltip: boolean = true;

    constructor() {
        this.measureSource = new VectorSource();
    }

    enable(map: Map, type: Type): void {
        this.map = map;
        this.setUp();
        this.setMessureTool(type);
        this.drawingFinished.next(null);
    }

    disable(removeExistingLayer: boolean) {
        if (!this.map) {
            return;
        }
        if (removeExistingLayer) {
            this.map.removeLayer(this.measureLayer);
            this.measureSource.clear();
        }
        olObservable.unByKey(<any>this.pointermoveEvent);
        this.map.getViewport().removeEventListener('mousemove', <any>this.mouseoutEvent);
        olObservable.unByKey(<any>this.mouseoutEvent);

        if (removeExistingLayer && this.measureTooltipElement) {
            this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
            this.measureTooltipElement = null;
            this.map.removeOverlay(this.measureTooltip);
            this.measureTooltip = null;

            const staticTooltips = document.getElementsByClassName('tooltip-static');

            while (staticTooltips.item(0)) {
                const item = staticTooltips.item(0);
                item.parentNode.removeChild(item);
            }
        }

        if (this.drawInteraction) {
            olObservable.unByKey(<any>this.drawStartEvent);
            this.drawInteraction.removeEventListener(null, <any>this.drawStartEvent);
            olObservable.unByKey(<any>this.drawEndEvent);
            this.drawInteraction.removeEventListener(null, <any>this.drawEndEvent);
            this.map.removeInteraction(this.drawInteraction);
        }
    }

    private setUp() {
        this.measureLayer = new VectorLayer({
            source: this.measureSource,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 0.3)'
                }),
                stroke: new Stroke({
                    color: '#1890ff',
                    width: 3
                }),
                image: new Circle({
                    radius: 7,
                    fill: new Fill({
                        color: '#1890ff'
                    })
                })
            })
        });
        this.map.addLayer(this.measureLayer);
    }

    private pointerMoveHandler(evt) {
        if (evt.dragging) {
            return;
        }
        let helpMsg = 'Click to start drawing';
        if (this.sketch) {
            helpMsg = this.continuePolygonMsg;
        }
    }

    private setMessureTool(type: Type) {
        this.pointermoveEvent = this.map.on('pointermove', () => this.pointerMoveHandler);

        this.drawInteraction = new olDraw({
            source: this.measureSource,
            type: type
        });
        this.map.addInteraction(this.drawInteraction);
        this.createMeasureTooltip();

        let listener;
        this.drawStartEvent = this.drawInteraction.on('drawstart', (drawStartEvt) => {
            this.measureSource.clear();
            this.drawingFinished.next(null);
            const staticTooltips = document.getElementsByClassName('tooltip-static');
            while (staticTooltips.item(0)) {
                const item = staticTooltips.item(0);
                item.parentNode.removeChild(item);
            }

            this.sketch = drawStartEvt.feature;
            let tooltipCoord = (<any>drawStartEvt.target).finishCoordinate_;
            listener = this.sketch.getGeometry().on('change', (evt) => {
                const geom = evt.target;
                const output = this.formatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
                if (this.measureTooltipElement) {
                    this.measureTooltipElement.innerHTML = output;
                    this.measureTooltip.setPosition(tooltipCoord);
                }
            });
        });

        this.drawEndEvent = this.drawInteraction.on('drawend', (data) => {
            this.sketch = null;
            this.measureTooltipElement.className = 'tooltip tooltip-static';
            this.measureTooltip.setOffset([-20, -60]);
            this.measureTooltipElement = null;
            this.createMeasureTooltip();
            olObservable.unByKey(listener);
            const geom = data.feature.getGeometry();
            const format = new wkt();
            this.drawingFinished.next(format.writeGeometry(geom));
        });

        return this.drawInteraction;
    }

    private formatArea(polygon) {
        const area = olSphere.getArea(polygon);
        let output;
        if (area > 10000) {
            output = Math.round(area / 1000000 * 100) +
                ' ' + 'ha';
        } else {
            const valueHa = (Math.round(area * 100) / 100) * 0.0001;
            output = Number(valueHa.toFixed(2)) +
                ' ' + 'ha';
        }
        return output;
    }

    private createMeasureTooltip() {
        if (this.measureTooltipElement) {
            this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
            this.map.removeOverlay(this.measureTooltip);
        }
        this.measureTooltipElement = document.createElement('div');
        this.measureTooltipElement.className = 'tooltip tooltip-measure';
        let layerClassName = 'measure-layer';
        if (!this.showMeassureTooltip) {
            layerClassName += ' hidden';
        }
        this.measureTooltip = new Overlay({
            element: this.measureTooltipElement,
            offset: [-20, -60],
            positioning: 'bottom-center',
            className: layerClassName
        });
        this.map.addOverlay(this.measureTooltip);
    }

    toggleTooltip(show: boolean): void {
        this.showMeassureTooltip = show;
        if (!this.measureTooltipElement) {
            return;
        }

        const tooltipLayers = document.getElementsByClassName('measure-layer');
        for (let i = 0; i < tooltipLayers.length; i++) {
            const l = tooltipLayers.item(i);
            l.classList.toggle('hidden')
        }
    }
}
