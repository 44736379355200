import { Control } from "ol/control";
import { Options } from "ol/control/Control";
import { Extent, isEmpty } from "ol/extent";
import VectorSource from "ol/source/Vector";

import { MapMeassureService } from "./map.meassure.service";

export class FitControl extends Control {

    mapMeasureService: MapMeassureService;
    btn: HTMLButtonElement;

    constructor(mapMeasureService: MapMeassureService) {
        const options = {} as Options;
        const button = document.createElement('button');

        const element = document.createElement('div');
        element.className = 'fit-control ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        this.mapMeasureService = mapMeasureService;
        button.addEventListener('click', this.onFitClick.bind(this), false);
        button.innerHTML = '<svg width="1em" height="1em" stroke-width="2" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="white" fill-opacity="0.01"/><path d="M12 39H44V7H12V15" stroke="#000000" stroke-width="4" stroke-linejoin="round"/><path d="M8 39H32V15H8V23" stroke="#000000" stroke-width="4" stroke-linejoin="round"/><rect x="4" y="23" width="16" height="16" fill="#2F88FF" stroke="#FFFFFF" stroke-width="4" stroke-linejoin="round"/></svg>';
        this.btn = button;
    }
    onFitClick() {
        const map = this.getMap();
        const source: VectorSource = this.mapMeasureService.measureSource;
        const extent: Extent = source.getExtent();
        if (!source || isEmpty(extent)) {
            return;
        }
        const currentResolution = map.getView().getResolution();
        map.getView().fit(extent, {
            minResolution: currentResolution
        });
    }
}
