import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommandResult } from '@lui/shared/models/command-result.model';
import { ESignaturePostCallbackData } from '@lui/shared/models/e-signature-post-callback-data.model';
import { ESignatureCallbackResult } from '@lui/shared/models/e-signature-callback-result.model';
import { lastValueFrom } from 'rxjs';
import { ESignatureLocalStorageKeys } from '@lui/shared/models/e-signature-local-storage-keys.enum';

@Injectable({
    providedIn: 'root',
})
export class ESignatureService {
    private baseApiUrl: string = '/api/v1/e-signature/';
    constructor(private httpClient: HttpClient) {}

    async getSigningFlowUrl(requestParams: HttpParams): Promise<CommandResult<string>> {
        return await lastValueFrom(this.httpClient.get<CommandResult<string>>(`${this.baseApiUrl}flow-url`, { params: requestParams }));
    }

    async eSignatureCallbackGet(params: HttpParams): Promise<CommandResult<ESignatureCallbackResult>> {
        return await lastValueFrom(this.httpClient.get<CommandResult<ESignatureCallbackResult>>(`${this.baseApiUrl}callback`, { params: params }));
    }

    async eSignatureCallbackPost(postCallbackData: ESignaturePostCallbackData, params: HttpParams): Promise<CommandResult<ESignatureCallbackResult>> {
        return await lastValueFrom(
            this.httpClient.post<CommandResult<ESignatureCallbackResult>>(`${this.baseApiUrl}callback-post`, postCallbackData, { params: params }),
        );
    }

    async closeActiveSigningSession(): Promise<void> {
        this.clearESignatureLocalStorageValues();
        await lastValueFrom(this.httpClient.post<CommandResult<void>>(`${this.baseApiUrl}close-session`, null));
    }

    clearESignatureLocalStorageValues(): void {
        Object.entries(ESignatureLocalStorageKeys).forEach(([key, value]) => {
            localStorage.removeItem(value);
        });
    }
}
