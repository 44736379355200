import { Component, Input } from '@angular/core';
import { DocumentsService } from '@lui/core/services/documents.service';
import { DATE_TIME_FORMAT } from '@lui/shared/constants';
import { GroupPermissionsType } from '@lui/shared/group-permissions-type.enum';
import { Document } from '@lui/shared/models';
import { DocumentFile } from '@lui/shared/models/document-file.model';
import { ESignatureSignableFileType } from '@lui/shared/models/e-signature-signable-file-type.enum';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'lui-document-files-table',
    templateUrl: './document-files-table.component.html',
    styleUrl: './document-files-table.component.less',
    standalone: false,
})
export class DocumentFilesTableComponent {
    GroupPermissions = GroupPermissionsType;
    ESignatureSignableFileTypes = ESignatureSignableFileType;
    dateTimeFormat: string = DATE_TIME_FORMAT;

    loading: boolean = false;

    @Input() document: Document;
    @Input() documentIsSignable: boolean;
    @Input() canEditFields: boolean;
    @Input() isEditMode: boolean;

    constructor(private documentsService: DocumentsService, private messageService: NzMessageService) {}

    async isSignableCheckedChanged(documentFile: DocumentFile): Promise<void> {
        if (this.isEditMode) {
            try {
                this.loading = true;
                await this.documentsService.updateDocumentFile(documentFile);
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error(ex);
                if (ex.error?.errors) {
                    ex.error.errors.forEach((error) => {
                        this.messageService.error(error);
                    });
                    return;
                }

                this.messageService.error('Kļūda labojot dokumenta failu');
            }
        }
    }

    async deleteDocumentFile(documentFileIndex: number): Promise<void> {
        if (!this.isEditMode) {
            this.document.documentFiles.splice(documentFileIndex, 1);
            this.document.documentFiles = [...this.document.documentFiles];
            return;
        }

        const documentFile: DocumentFile = this.document.documentFiles.at(documentFileIndex);
        if (!documentFile) {
            return;
        }

        if (documentFile.id) {
            try {
                this.loading = true;
                await this.documentsService.deleteDocumentFile(this.document.id, documentFile.id);
                this.document.documentFiles.splice(documentFileIndex, 1);
                this.document.documentFiles = [...this.document.documentFiles];
                this.messageService.success('Dokumenta fails dzēsts');
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error(ex);
                if (ex.error?.errors) {
                    ex.error.errors.forEach((error) => {
                        this.messageService.error(error);
                    });
                    return;
                }

                this.messageService.error('Kļūda dzēšot dokumenta failu');
            }
        }
    }
}
