import { Injectable } from '@angular/core';

import { Feature, Map, View } from 'ol';
import LayerSwitcher from 'ol-layerswitcher';
import { Attribution, defaults as defaultControls, ScaleLine } from 'ol/control';
import * as olExtent from 'ol/extent';
import WKT from 'ol/format/WKT';
import { default as LayerGroup, default as olGroup } from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import * as Proj from 'ol/proj';
import { register } from 'ol/proj/proj4';
import BingMaps from 'ol/source/BingMaps';
import ImageArcGISRest from 'ol/source/ImageArcGISRest';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import TileState from 'ol/TileState';
import proj4 from 'proj4';

import { AuthService } from '@lui/core/services/auth.service';
import { BaseLayersService } from '@lui/core/services/base-layers.service';
import { CadastreInfoService } from '@lui/core/services/cadastre-info.service';
import { AddressPoint } from '@lui/shared/models/address-point.model';
import { BaseLayer } from '@lui/shared/models/base-layer.model';
import { CadastreGeom } from '@lui/shared/models/cadastre-geom.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Geometry, Point } from 'ol/geom';
import CircleStyle from 'ol/style/Circle';
import { AddressSelectModalComponent } from './address-select-modal/address-select-modal.component';
import { FitControl } from './fit-control';
import { MapDrawControl } from './map-draw-control';
import { MapMeassureService } from './map.meassure.service';
import { MeasureTooltipControl } from './measure-tooltip-control';
import { ZoomToFitControl } from './zoom-to-fit-control';

interface LayerSettings {
    source: any;
    title: string;
    type?: string;
}

@Injectable({ providedIn: 'root' })
export class MapService {
    get map(): Map {
        return this._map;
    }

    get cadastreGeomVectorLayer(): VectorLayer {
        return this._cadastreGeomVectorLayer;
    }

    get cadastreSearchVectorLayer(): VectorLayer {
        return this._cadastreSearchVectorLayer;
    }

    private _map: Map;
    private _cadastreGeomVectorLayer: VectorLayer;
    private _cadastreSearchVectorLayer: VectorLayer;

    constructor(
        private baseLayersService: BaseLayersService,
        private authService: AuthService,
        private mapMeasureService: MapMeassureService,
        private cadastreInfoService: CadastreInfoService,
        private messageService: NzMessageService,
        private modalService: NzModalService,
    ) {}

    async initMap(): Promise<void> {
        const baseLayers = await this.getBaseLayers();

        proj4.defs(
            'EPSG:3059',
            '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
        );
        register(proj4);

        const attribution = new Attribution({
            collapsible: true,
        });

        if (this._map) {
            this._map.setTarget(null);
            this._map = null;
        }

        this._map = new Map({
            target: 'map',
            layers: baseLayers,
            view: new View({
                center: Proj.fromLonLat([23.9890828, 56.9713962]),
                zoom: 10,
            }),
            controls: defaultControls({ attribution: false }).extend([attribution]),
        });

        const layerSwitcher = new LayerSwitcher({ activationMode: 'click' });
        this.map.addControl(layerSwitcher);
        this.map.addControl(new MapDrawControl(this.mapMeasureService));
        this.map.addControl(new MeasureTooltipControl(this.mapMeasureService));
        this.map.addControl(new ZoomToFitControl(this.mapMeasureService));
        this.map.addControl(new FitControl(this.mapMeasureService));
        const scaleLine = new ScaleLine({
            units: 'metric',
            bar: true,
            steps: 5,
            text: true,
            minWidth: 140,
        });
        this.map.addControl(scaleLine);

        this.map.once('postrender', () => {
            this.map.updateSize();
            this.map.renderSync();
        });
    }

    createCadastreGeomLayer(cadastreGeoms: CadastreGeom[]): void {
        const features: Feature[] = [];

        cadastreGeoms.forEach((geomObj) => {
            if (!geomObj || !geomObj.cadastreNumber || !geomObj.geom) {
                return;
            }

            const format = new WKT();
            const transformedGeom = format.readGeometry(geomObj.geom);
            const feature = new Feature({
                geometry: transformedGeom,
                cadastreNumber: geomObj.cadastreNumber,
            });
            feature.setStyle(
                new Style({
                    text: new Text({
                        text: feature.get('cadastreNumber'),
                        fill: new Fill({ color: 'rgba(0,0,0,1)' }),
                        font: 'bold 16px Arial',
                        overflow: true,
                        rotateWithView: true,
                    }),
                    stroke: new Stroke({ color: '#932542', width: 4 }),
                    fill: new Fill({ color: 'rgba(242, 77, 15, 0.5)' }),
                }),
            );
            features.push(feature);
        });

        const cadastreGeomSource: VectorSource = new VectorSource({
            features: features,
        });

        const cadastreGeomVectorLayer: VectorLayer = new VectorLayer({
            source: cadastreGeomSource,
        });

        this.map.removeLayer(this._cadastreGeomVectorLayer);
        this.map.addLayer(cadastreGeomVectorLayer);

        this._cadastreGeomVectorLayer = cadastreGeomVectorLayer;
        this.fit();
    }

    clearCadastreGeomsLayer(): void {
        if (this.map) {
            this.map.removeLayer(this._cadastreGeomVectorLayer);
        }
        this._cadastreGeomVectorLayer = null;
    }

    async searchVzd(vzdSearchString: string): Promise<void> {
        if (!this._cadastreSearchVectorLayer) {
            this._cadastreSearchVectorLayer = new VectorLayer({
                source: new VectorSource({
                    features: [],
                }),
            });

            if (this.map) {
                this.map.addLayer(this._cadastreSearchVectorLayer);
            }
        }

        if (/^\d+$/.test(vzdSearchString)) {
            // if string contains only numbers, then it should be cadastre search
            await this.handleCadastreGeomSearch(vzdSearchString);
        } else {
            await this.handleAddressSearch(vzdSearchString);
        }
    }

    clearVzdSearchLayer(): void {
        if (this._map) {
            this._map.removeLayer(this._cadastreSearchVectorLayer);
        }
        this._cadastreSearchVectorLayer = null;
    }

    async getBaseLayers(): Promise<olGroup[]> {
        const baseLayerConfigs = await this.baseLayersService.getActiveBaseLayers();
        const baseLayers: any[] = [];
        const overlayLayers: any[] = [];
        baseLayerConfigs.forEach((l) => {
            if (l.layerType !== 'TileLayer' && l.layerType !== 'ImageLayer') {
                return;
            }
            let baseLayer = null;
            switch (l.sourceType) {
                case 'XYZ':
                    const xSource = new XYZ(JSON.parse(l.sourceJson));
                    const settings = {
                        source: xSource,
                        title: l.name,
                        crossOrigin: 'anonymous',
                    } as LayerSettings;
                    if (!l.isOverlay) {
                        settings.type = 'base';
                    }
                    baseLayer = new TileLayer(settings);
                    break;
                case 'BingMaps':
                    const settingsBing = {
                        source: new BingMaps(JSON.parse(l.sourceJson)),
                        title: l.name,
                        crossOrigin: 'anonymous',
                    } as LayerSettings;
                    if (!l.isOverlay) {
                        settingsBing.type = 'base';
                    }
                    baseLayer = new TileLayer(settingsBing);
                    break;
                case 'ImageArcGISRest':
                    const layerSource = JSON.parse(l.sourceJson);
                    const arcgisLayer = new ImageLayer(<any>{
                        source: new ImageArcGISRest({ ...layerSource, crossOrigin: 'anonymous', hidpi: false }),
                        title: l.name,
                    });
                    baseLayer = arcgisLayer;
                    break;
                case 'TileWMS':
                    const tileLayerConfig = JSON.parse(l.sourceJson);
                    if (l.useJwt) {
                        tileLayerConfig.tileLoadFunction = (tile, src) => this.loadFunction(tile, src);
                    }

                    const tileSettingsWms = {
                        source: new TileWMS({
                            ...tileLayerConfig,
                            crossOrigin: 'anonymous',
                        }),
                        title: l.name,
                    } as LayerSettings;
                    if (!l.isOverlay) {
                        tileSettingsWms.type = 'base';
                    }
                    baseLayer = new TileLayer(tileSettingsWms);
                    break;
                case 'ImageWMS':
                    baseLayer = this.getImageLayer(l);
                    break;
            }
            if (baseLayer) {
                baseLayer.setVisible(l.isEnabledByDefault);
                if (l.isOverlay) {
                    overlayLayers.push(baseLayer);
                } else {
                    baseLayers.push(baseLayer);
                }
                baseLayer.getSource().setAttributions(l.attribution);
            }
        });

        return [
            new olGroup(<any>{
                title: 'Base maps',
                layers: [...baseLayers],
                fold: true,
            }),
            new olGroup(<any>{
                title: 'Overlay',
                layers: [...overlayLayers],
                fold: true,
            }),
        ];
    }

    fit() {
        const extent = olExtent.createEmpty();
        this.map.getLayers().forEach((layer) => {
            if (layer instanceof LayerGroup) {
                layer.getLayers().forEach((groupLayer) => {
                    if (layer instanceof VectorLayer) {
                        olExtent.extend(extent, (<VectorLayer>groupLayer).getSource().getExtent());
                    }
                });
            } else if (layer instanceof VectorLayer) {
                olExtent.extend(extent, (<VectorLayer>layer).getSource().getExtent());
            }
        });

        this.map.getView().fit(extent, { size: this.map.getSize(), padding: [20, 20, 20, 20] });
    }

    private getImageLayer(l: BaseLayer): ImageLayer<any> {
        const imageLayerConfig = JSON.parse(l.sourceJson);

        if (l.useJwt) {
            imageLayerConfig.imageLoadFunction = (img, src) => this.loadFunction(img, src);
        }

        const imageSettingsWms = {
            source: new ImageWMS({
                ...imageLayerConfig,
                crossOrigin: 'anonymous',
            }),
            title: l.name,
        } as LayerSettings;
        if (!l.isOverlay) {
            imageSettingsWms.type = 'base';
        }
        return new ImageLayer(imageSettingsWms);
    }

    private loadFunction(tile, src) {
        const client = new XMLHttpRequest();
        client.open('GET', src);
        const JWT = `Bearer ${this.authService.token}`;
        client.setRequestHeader('Authorization', JWT);
        client.responseType = 'blob';
        client.addEventListener('loadend', function (evt) {
            const data = this.response;
            if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
            } else {
                tile.setState(TileState.ERROR);
            }
        });
        client.send();
    }

    private async handleCadastreGeomSearch(cadastreNumber: string): Promise<void> {
        try {
            var cadastreGeom: CadastreGeom = (await this.cadastreInfoService.getCadastreGeom(cadastreNumber)).data;
        } catch (ex) {
            console.error(ex);
            this.messageService.error('Kļūda meklējot kadastra ģeometriju');
        }

        if (!cadastreGeom || !cadastreGeom.cadastreNumber || !cadastreGeom.geom) {
            this.messageService.warning('Kadastra ģeometrija nav atrasta');
            return;
        }

        if (
            this._cadastreSearchVectorLayer
                .getSource()
                .getFeatures()
                .find((f) => f.get('cadastreNumber') == cadastreGeom.cadastreNumber)
        ) {
            return;
        }

        const format = new WKT();
        const transformedGeom: Geometry = format.readGeometry(cadastreGeom.geom);
        const feature = new Feature({
            geometry: transformedGeom,
            cadastreNumber: cadastreGeom.cadastreNumber,
        });
        feature.setStyle(
            new Style({
                text: new Text({
                    text: feature.get('cadastreNumber'),
                    fill: new Fill({ color: 'rgba(0,0,0,1)' }),
                    font: 'bold 16px Arial',
                    overflow: true,
                    rotateWithView: true,
                }),
                stroke: new Stroke({ color: '#1f0fab', width: 4 }),
                fill: new Fill({ color: 'rgba(31, 15, 171, 0.5)' }),
            }),
        );

        this._cadastreSearchVectorLayer.getSource().addFeature(feature);
        this.fit();
    }

    private async handleAddressSearch(searchText: string): Promise<void> {
        try {
            var addressPoints: AddressPoint[] = (await this.cadastreInfoService.getAddressPoints(searchText)).data;
        } catch (ex) {
            console.error(ex);
            this.messageService.error('Kļūda meklējot adreses');
        }

        if (!addressPoints || addressPoints.length == 0) {
            this.messageService.warning('Adreses nav atrastas');
            return;
        }

        const modal: NzModalRef<AddressSelectModalComponent> = this.modalService.create({
            nzTitle: 'Adreses meklēšana',
            nzContent: AddressSelectModalComponent,
        });

        modal.afterOpen.subscribe(() => {
            modal.getContentComponent().addressPoints = addressPoints;
            modal.getContentComponent().filteredAddresses = addressPoints;
        });

        modal.afterClose.subscribe((selectedAddressPoint: AddressPoint) => {
            if (!selectedAddressPoint || !selectedAddressPoint.fullAddress || !selectedAddressPoint.point) {
                return;
            }

            if (
                this._cadastreSearchVectorLayer
                    .getSource()
                    .getFeatures()
                    .find((f) => f.get('fullAddress') == selectedAddressPoint.fullAddress)
            ) {
                return;
            }

            const format = new WKT();
            const transformedGeom: Point = <Point>format.readGeometry(selectedAddressPoint.point);
            const feature = new Feature({
                geometry: transformedGeom,
                fullAddress: selectedAddressPoint.fullAddress,
            });
            feature.setStyle(
                new Style({
                    text: new Text({
                        text: feature.get('fullAddress'),
                        fill: new Fill({ color: 'rgba(0,0,0,1)' }),
                        font: 'bold 16px Arial',
                        offsetY: -15,
                        rotateWithView: true,
                    }),
                    image: new CircleStyle({
                        radius: 5,
                        stroke: new Stroke({ color: '#1f0fab', width: 1 }),
                        fill: new Fill({ color: 'rgba(31, 15, 171, 0.5)' }),
                    }),
                }),
            );

            this._cadastreSearchVectorLayer.getSource().addFeature(feature);
            const flatCoordinates: number[] = transformedGeom.getFlatCoordinates();
            this._map.getView().setCenter([flatCoordinates[0], flatCoordinates[1]]);
            this._map.getView().setZoom(19);
        });
    }
}
